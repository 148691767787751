.top-nav {
  height: var(--headerbar-height);
  // width: 100%;
  background-color: var(--navbar-bg);
}
.nav-menu-cont {
  position: absolute;
  top: 42px;
  right: 0;
  width: 200px !important;
  height: calc(100dvh - 42px);
  z-index: 11;
  overflow-y: auto;
  box-shadow: -1px 0 5px -2px #888;
}

#logo_container {
  display: none;
}
#nav_container {
  display: flex;
  justify-content: space-around;
  #nav_container_icon {
    display: none;
  }
  #nav_container_menu {
    display: block;
  }
}
@media (max-width: 525px) {
  #logo_container {
    display: block;
  }
  #nav_container {
    #nav_container_icon {
      display: block;
    }
    #nav_container_menu {
      display: none;
    }
  }
}

.nav-menu-login-link {
  width: 90%;
}

.desk-user-menu {
  position: absolute;
  right: 0;
  top: 42px;
  box-shadow: -1px 0 5px -2px #888;
  padding-right: 10px;
  overflow-y: auto;
  z-index: 11;
}

.nav-expand-menu-btn-txt {
  width: 133px;
  overflow-x: auto;
}

.nav-register-btn {
  background-color: #0c543c;
  display: flex;
  color: #f8fdfd;
  padding: 5px;
  padding: 4px 8px 4px 8px;
  gap: 8px;
  border-radius: 4px;
  font-weight: 600;
}
.nav-register-btn:hover {
  box-shadow: 0px 2px 8px 1px #00000029;
  box-shadow: 0px 1px 4px 1px #0000001f;
}

.left-sidebar-btn {
  position: fixed !important;
  // top: 8% !important;
  border-radius: 0 3px 3px 0 !important;
  background: #f8f8f8 !important;
  // left: 0;
  color: #30d5c8 !important;
}
