@import "../../../App.scss";

$res-mat-table-accent-col: #a7a7a7;

#resource-matrix-table {
  margin-bottom: 16px;
  max-width: 1080px;

  td {
    border: 2px solid $res-mat-table-accent-col;
  }

  th,
  td {
    padding: 12px;
  }

  th {
    background-color: transparent;
    color: $res-mat-table-accent-col;
  }

  tr {
    background-color: transparent;
  }

  td {
    height: 70px;
    padding: 2px;
    width: 28%;

    > div {
      // border: 1px solid red;
      flex-wrap: wrap;
      justify-content: space-evenly;
      height: 100%;
      overflow-y: auto;
      align-items: center;

      > div {
        // border: 1px solid red;
        border-radius: 5px;
        margin: 1px 2px;
        padding: 3px 6px;
        cursor: pointer;
        height: fit-content;
        text-transform: capitalize;
      }
    }
  }
}

.res-mat-very-high-falling {
  background-color: rgba(199, 226, 45, 0.75);
}
.res-mat-very-high-stagnant {
  background-color: rgba(126, 190, 45, 0.75);
}
.res-mat-very-high-rising {
  background-color: rgba(51, 255, 45, 0.75);
}

.res-mat-high-falling {
  background-color: rgba(226, 178, 45, 0.75);
}
.res-mat-high-stagnant {
  background-color: rgba(199, 226, 45, 0.75);
}
.res-mat-high-rising {
  background-color: rgba(126, 190, 45, 0.75);
}

.res-mat-medium-falling {
  background-color: rgba(226, 122, 45, 0.75);
}
.res-mat-medium-stagnant {
  background-color: rgba(226, 178, 45, 0.75);
}
.res-mat-medium-rising {
  background-color: rgba(199, 226, 45, 0.75);
}

.res-mat-low-falling {
  background-color: rgba(226, 96, 45, 0.75);
}
.res-mat-low-stagnant {
  background-color: rgba(226, 122, 45, 0.75);
}
.res-mat-low-rising {
  background-color: rgba(226, 178, 45, 0.75);
}

.res-mat-very-low-falling {
  background-color: rgba(226, 72, 45, 0.75);
}
.res-mat-very-low-stagnant {
  background-color: rgba(226, 96, 45, 0.75);
}
.res-mat-very-low-rising {
  background-color: rgba(226, 122, 45, 0.75);
}

.res-mat-strip {
  margin: 7px 4px !important;
}
.res-mat-strip:hover {
  transform: scale(101%);
  box-shadow: 0 0 1px 1px rgba($accent-color, 0.5);
}

@media screen and (min-width: 1270px) {
  .met-spec-text-cont {
    border-left: 1px solid rgba(128, 128, 128, 0.3);
  }
}

@media screen and (max-width: 1270px) {
  .met-spec-text-cont {
    border-left: none;
    border-top: 1px solid rgba(128, 128, 128, 0.3);
    padding-top: 30px;
    margin-top: -29px;
  }
}
