.navigation-label {
  font-size: var(--sylvr-h4-fs) !important;
  font-weight: 400 !important;
  text-align: left;
  color: #1e1e1e !important;
  padding: 8px 0px !important;
  border-bottom: 2px solid #b1b1b1;
  width: 100%;
  .search-box {
    padding: 4px;
    border-radius: 4px;
    background-color: #5d5d5d;
    width: 22px;
  }
  .search-box-label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding-left: 5px;
    color: #1e1e1e !important;
    font-size: var(--sylvr-h4-fs) !important;
    font-weight: 600 !important;    
  }
}
.search-container {
  display: flex;
}

.left-menu-container {
  padding: 5px;
  position: absolute;
  .left-menu {
    position: relative;
    top: 18px;
    width: 190px;
  }
}

.navigation-label-industry {
  font-size: var(--sylvr-h4-fs) !important;
  font-weight: 400 !important;
  text-align: left;
  color: #1e1e1e !important;
  padding: 8px 0px !important;
  width: 100%;
  .search-box {
    padding: 4px;
    border-radius: 4px;
    background-color: #5d5d5d;
    width: 22px;
  }
  .search-box-label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding-left: 5px;
    color: #1e1e1e !important;
    font-size: var(--sylvr-h4-fs) !important;
    font-weight: 600 !important;
    width: 100%;
  }
}

.industry-box {
  display: flex;
  .industry-icon {
    width: 20px;
    height: 20px;
  }
  .industry-label-container {
    display: flex;
    padding-left: 5px;
    cursor: pointer;
    .industry-label-text {
      font-size: var(--sylvr-h4-fs) !important;
      font-weight: 400;
      text-align: left;
      color: #1E1E1E;
    }
  }
}
