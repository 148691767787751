@import "../../App.scss";

.home-page-card {
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.chart-container {
  background-color: #fff !important;
  border-radius: 4px;
}
