@import "../scss/variables";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  /* Color Text*/
  --primary-light: #417a66;
  --primary-dark: #284e41;

  /*Font Size*/

  --heading: 18px;

  --sub-nav: 14px;
  --sub-tab: 12px;

  --navbar-bg: #e2e8f0;
  --headerbar-height: 44px;
  --footerbar-height: 44px;

  --body-bg: #fff;

  // Global setting variables

  --sylvr-bg-color:#F8FDFD;

  --sylvr-h1-fs: 20px;
  --sylvr-h1-fw: bold;

  --sylvr-h2-fs: 18px;
  --sylvr-h2-fw: bold;

  --sylvr-h3-fs: 16px;
  --sylvr-h3-fw: bold;

  --sylvr-h4-fs: 14px;

  --sylvr-subtitle-fs: 14px;

  --sylvr-regular-text-fs: 14px;

  --sylvr-text-color: #92acac;
  --sylvr-text-color-selected: #30d5c8;

  --sylvr-observation-bg-color:#FFAA00;

  --sylvr-navbar-bg: #e2e8f0;
  --sylvr-footerbar-height: 44px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: "Poppins", sans-serif !important;
}

/* Webkit Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
  height: 8px; /* Set the height of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set the background color of the track */
  border-radius: 10px; /* Set the border radius of the track */
}

::-webkit-scrollbar-thumb {
  background: #605656; /* Set the background color of the thumb */
  border-radius: 10px; /* Set the border radius of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Set the background color of the thumb on hover */
}

.app-container {
  height: 100dvh;

  > * {
    width: 100%;
  }
}

.body-container {
  height: 100%;
  // border: 1px solid red;

  max-width: 4320px;
  margin: auto !important;
}

// .auth-page {
//   > * {
//     // border: 2px solid red;
//     // box-shadow: 0px 0px 2px 1px #80808065;
//   }
// }

// chakra UI select
.chakra-select__wrapper {
  display: inline-block;
}

// recharts styling
.recharts-wrapper {
  // width: fit-content !important;
  width: 500px;
}

// table styling
/* table {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
}
th,
td {
  padding: 8px 12px;
}
th {
  position: sticky;
  top: 0px;
  background-color: rgba($accent-color, 1);
  z-index: 10;
}
tr:nth-child(even) {
  background-color: rgba($accent-color, 0.05);
}
tr:hover {
  background-color: rgba($accent-color, 0.1);
}

table,
th {
  border: 1px solid rgba($accent-color, 0.1);
} */

::-webkit-file-upload-button {
  display: none;
}
::file-selector-button {
  display: none;
}
.css-1jqq78o-placeholder {
  white-space: nowrap;
}
.css-b62m3t-container {
  width: 100%;
}
@media (max-width: 991px) {
  .agency-main-content .body-container {
    height: auto !important;
  }
  //   .body-container {
  //     height: auto;
  //     padding:20px 0px;

  // }
  .agency-main-content {
    height: calc(100vh - 64px);
  }
}
@media (max-width: 575px) {
  .body-container {
    height: auto;
    // padding: 30px;
  }
  .agency-main-content {
    height: calc(100vh + 100px);
  }
}
@media (max-width: 767px) {
  .login-page .chakra-stack.auth-page {
    width: 100%;
  }
  .body-container {
    // padding: 20px;
  }
}

.agency-client {
  background: #f8f8f8;
}

.agency-content {
  background: #fff;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}

.rightbar {
  background: #f8f8f8;
}
.rightbar button {
  color: #8f8f8f;
  font-size: 16px;
  font-weight: 600;
}
.rightbar button.rightbar-heading {
  background: #e6e6e6;
  color: #1e1e1e;
}
.client-background {
  margin: 15px 0 30px;
}
.client-background a {
  color: #8f8f8f;
  font-size: 16px;
  font-weight: 600;
}
.custom-input .chakra-form-control > div,
.custom-input .chakra-form-control {
  max-width: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.custom-input .chakra-form-control .chakra-form__error-message {
  position: absolute;
  top: 85%;
  font-size: 12px;
  width: 100%;
  left: 0;
}
.custom-input label {
  padding: 8px 20px;
  background-color: #e6e6e6;
  margin: 0;
  min-width: 100px;
  text-align: center;
  white-space: nowrap;
}
.custom-input input:not(::placeholder) {
  border: 3px solid #e6e6e6 !important;
  background: #fff;
  border-radius: 0 !important;
  box-shadow: none !important ;
  width: 100%;
  outline: none !important;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.custom-input input.css-sdfhfu {
  border-radius: 0;
  box-shadow: none !important ;
  border: 3px solid #e6e6e6 !important;
  border-radius: 0 !important;
  line-height: 2;
}
.custom-input .chakra-form-control:has(input[aria-invalid="true"]) label {
  background: #ffc0c0;
}
.custom-input input[aria-invalid="true"] {
  border: 3px solid #ffc0c0 !important;
  box-shadow: none;
}
body:has(.agency-main-content) {
  background: #f8f8f8;
}
.agency-main-content .body-container {
  height: calc(100vh - 130px);
}

@media (max-width: 575px) {
  .agency-content .chakra-link {
    font-size: 14px;
  }
}
.agency-content {
  height: calc(100% - 145px);
}
body {
  background: var(--sylvr-bg-color) !important;
}
.recharts-responsive-container,
.recharts-surface,
.recharts-wrapper {
  height: 100% !important;
  width: 100% !important;
}

.menu-label-arrow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
}

.menu-label-arrow-selected {
  font-weight: 600 !important;
}

.menu-arrow {
  width: 10px;
  height: 5px;
  margin-top: 13px;
  margin-right: 13px;
}

// Charts CSS
.sylvr-graph-card {
  width: 100%;
  height: 100%;
  background-color: #f2f7f7;
  border-radius: 4px;
  border-width: 1px;
  padding: 5px;
  margin: 0px 0px 10px 0px;

  .sylvr-graph-card-heading {
    .sylvr-graph-card-heading-label {
      font-weight: 600;
      font-size: var(--sylvr-h3-fs);
      padding: 5px 10px;
      display: inline-block;
      color: var(--sylvr-text-color-selected);
      border-radius: 4px;
      background-color: #f8fdfd;
    }
  }
}
