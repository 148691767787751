@import "../../App.scss";

// @media screen and (max-width: 343px) {
//   .common-container {
//     justify-content: flex-start;
//   }
// }

.navmenu{
  padding: 0 20px;
}
.navmenu button{
  color: #8F8F8F;
  font-size: 20px;
  font-weight: 600;
  margin-left:25px;
}
.navmenu button:hover {
  color:#1E1E1E;
  
  text-decoration: none;
  
}
.login-page{
  background: linear-gradient(281deg, #B1B1B1 0%, #E6E6E6 100%);
} 
.login-page input{
  border: none !important;
  box-shadow:none !important ;
}

.login-page input::placeholder{
  color:#8F8F8F;
  font-size: 14px ;
}
.forgot-btn:hover{
  color: #fff;
  background: #8f8f8f!important;
}
.login-btn:hover{
  
  color: #8f8f8f;
  background: #fff!important;
}
.signup-btn{
  box-shadow:none !important;
  width: 100%;
  margin-top:30px !important;
  display: block;
}
@media(max-width:991px){
  .signup-btn{
    margin-top:20px !important;
  }
}
