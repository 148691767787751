.drag-drop-box {
  height: 100%;

  > label {
    margin: auto;
    height: 100%;
    width: 100%;
    max-width: 100%;

    > svg {
      display: none;
    }
  }

  .rddf {
    border: 2px solid gray;
  }
}

.sc-ewnqHT,
.fDoQYg {
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin: 12px 0;
  }

  > span {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      margin: 7px 0;
    }

    > span {
      font-size: large;
      text-decoration: none;
    }
  }
}

.file-types,
.kyUZVo {
  width: 100% !important;
  max-width: none !important;
}
