#industry_container {
  box-shadow: 0px 0px 10px 2px #0000001a;
  width: 100%;
  height: calc(
    100vh - var(--headerbar-height) - var(--footerbar-height) - 103px
  );
  position: relative;
}

#industry_name {
  align-items: center;
  justify-content: space-between;
  min-height: 40px !important;
  max-height: 40px !important;
}

#industry_tab {
  .main-agency-tab {
    padding-top: 15px;
    .chakra-tabs__tablist {
      border: none !important;
    }
    .chakra-tabs__tab {
      border-radius: 12px 12px 0px 0px;
      background: #f8f8f8;
      padding: 5px 30px;
      color: var(--sylvr-text-color);
      font-size: var(--sylvr-subtitle-fs);
      font-weight: 600;
      margin-right: 25px;
      border: none !important;
    }
    .css-1mreahs[aria-selected="true"],
    .css-6z8agw[aria-selected="true"] {
      border: none !important;
      border-radius: 12px 12px 0px 0px;
      background: #fff;
      box-shadow: 0px -4px 4px 2px rgba(0, 0, 0, 0.08);
      color: var(--sylvr-text-color-selected);
    }
  }
}

#segment_risk_container {
  height: 100%;
  // margin: 0px 0px 0px 5px;
  width: 100%;
  #segment_risks_type {
    min-width: 200px !important;
    max-width: 200px !important;
    overflow-y: auto;
    align-items: center;
    padding: 10px;
    min-height: 100%;
    max-height: 100%;
    // min-height: calc(100% - var(--sylvr-footerbar-height));
    // max-height: calc(100% - var(--sylvr-footerbar-height));
    .riskSubTabs {
      border: 1px solid #f8f8f8;
      padding: 6px 15px;
      margin-right: 15px;
      border-width: 1px;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 400;
      font-family: Inter !important;
      font-size: 13px !important;
      line-height: 15.73px;
      text-align: left;
    }
    .selected {
      background-color: #f8f8f8;
      color: #1e1e1e;
      font-weight: 600;
    }
  }
  #segment_risks_content {
    height: calc(100% - 40px);
    // width: calc(100% - 200px);
    display: flex;
    #segment_risks_graph {
      margin: 0px 10px;
      width: 60%;
      height: 98%;
      position: relative;
      .activity-content-graph {
        overflow-y: auto;
        justify-items: center;
        height: 100%;
        width: 100%;
        position: absolute;
      }
      @media (max-width: 991px) {
        width: 100%;
      }
    }

    @media (max-width: 991px) {
      display: block;
      overflow-y: auto;
    }
  }
}

#competitive_risk_container {
  height: 100%;
  margin: 0px 0px 0px 5px;
  width: 100%;
  #competitive_risks_type {
    min-width: 200px !important;
    max-width: 200px !important;
    overflow-y: auto;
    margin: 1px;
    align-items: center;
    padding: 10px 3px;
    min-height: 100%;
    max-height: 100%;

    .riskSubTabs {
      border: 1px solid #f8f8f8;
      padding: 6px 15px;
      margin-right: 15px;
      border-width: 1px;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 400;
      font-family: Inter !important;
      font-size: 13px !important;
      line-height: 15.73px;
      text-align: left;
    }
    .selected {
      background-color: #f8f8f8;
      color: #1e1e1e;
      font-weight: 600;
    }
  }
  #competitive_risks_content {
    height: 100%;
    width: calc(100% - 200px);
    display: flex;
    #competitive_risks_graph {
      margin: 10px;
      width: 60%;
      height: 100%;
      #activityLabel {
        font-family: Inter !important;
        font-size: 14px;
        font-weight: 600;
        color: #1e1e1e;
        // padding: 15px 0px 5px 0px;
        padding: 5px 0px 12px 0px;
      }
      .activity-content-graph {
        overflow: auto;
        justify-items: center;
        height: calc(
          100vh - 143px - var(--headerbar-height) - var(--footerbar-height)
        );
        position: relative;
      }
    }
    #segment_risks_observations {
      width: 40%;
      height: 100%;
      #segment_risks_observations_content {
        padding: 2px;
        margin-top: -50px;
        height: 100%;
        #observations_container {
          width: 100%;
          height: 100%;
          border-width: 1px;
          border-radius: 8px;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
          #observations_container_label {
            text-align: left;
            color: #1e1e1e;
            font-weight: 600;
            font-size: 16px;
            padding: 2px 0px 0px 2px;
            margin-bottom: 2px;
          }
          #observations_content {
            padding: 2px;
            border-width: 2px;
            border-color: #e6e6e6;
            border-radius: 4px;
            height: calc(
              100vh - 177px - var(--headerbar-height) - var(--footerbar-height)
            );
            overflow: auto;
          }
        }
      }
      #segment_risks_advisory_content {
        padding: 2px;
        // margin-top: -50px;
        height: 100%;
        background-color: #f8f8f8;
        #observations_container {
          width: 100%;
          height: 100%;
          border-width: 1px;
          border-radius: 8px;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
          #observations_container_label {
            text-align: left;
            color: #1e1e1e;
            font-weight: 600;
            font-size: 16px;
            padding: 2px 0px 0px 2px;
            margin-bottom: 2px;
          }
          #observations_content {
            padding: 2px;
            border-width: 2px;
            border-color: #e6e6e6;
            border-radius: 4px;
            height: calc(
              100vh - 177px - var(--headerbar-height) - var(--footerbar-height)
            );
            overflow: auto;
          }
        }
      }
    }
  }
}

#segment_risks_observations_registrations {
  cursor: pointer;

  #unlock_registration_container {
    background-color: #5d5d5d;
    height: 60px;
    color: white;
    padding: 2px;
    border-radius: 5px;
    margin: 2px;
    #registration_unlock {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

#segment_activity_unlock {
  padding: 5px;
  z-index: 1;
  margin: 2px 10px;
  position: relative;
  height: 60px;
  top: calc(100vh - 300px - var(--headerbar-height) - var(--footerbar-height));
}

@media (max-width: 525px) {
  #industry_name {
    margin-left: 10px;
  }
  #industry_tab {
    margin-left: 10px;
    margin-right: 10px;
    .main-agency-tab .chakra-tabs__tab {
      padding: 5px;
      font-size: 12px;
      margin-right: 14px;
    }
  }
  #segment_risk_container {
    margin: 0px;
    #segment_risks_type {
      margin: 0px;
      min-width: 90px !important;
      max-width: 90px !important;
      .riskSubTabs {
        padding: 5px;
        margin-right: 8px;
      }
    }
    #segment_risks_content {
      display: block;
      width: calc(100% - 90px);
      #segment_risks_graph {
        margin: 0px;
        width: 100%;
        #activityLabel {
          padding-left: 8px;
        }
        .activity-content-graph {
          overflow: auto;
          top: -60px;
          height: calc(100vh - 70vh);
        }
      }
      #segment_risks_observations {
        margin: 0px;
        width: 100%;
        #segment_risks_observations_content {
          margin-top: -100px;
          #observations_container {
            .observation-label {
              padding: 0px;
            }
            #observations_container_label {
              font-size: 14px;
            }
            #observations_content {
              overflow: auto;
              height: 20vh;
            }
          }
        }
        #segment_risks_advisory_content {
          margin-top: -100px;
          background-color: #f8f8f8;
          #observations_container {
            .observation-label {
              padding: 0px;
            }
            #observations_container_label {
              font-size: 14px;
            }
            #observations_content {
              overflow: auto;
              height: 20vh;
            }
          }
        }
      }
    }
  }
  #segment_risks_observations_registrations {
    #unlock_registration_container {
      height: 40px;
      width: 92%;
      #registration_unlock {
        font-size: 12px;
      }
    }
  }
  #observations_unlock {
    top: calc(25vh - var(--headerbar-height) - var(--footerbar-height));
  }

  #segment_activity_unlock {
    top: calc(
      100vh - 415px - var(--headerbar-height) - var(--footerbar-height)
    );
  }
}

.labelType {
  padding: 3px 15px 3px 0px;
  margin-right: 30px;
  font-weight: 600;
}
.riskSubTabs {
  border: 1px solid #f8f8f8;
  padding: 6px 15px;
  margin-right: 15px;
  border-width: 1px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 400;
  font-family: Inter !important;
  font-size: 13px !important;
  line-height: 15.73px;
  text-align: left;
}
.selected {
  background-color: #f8f8f8;
  color: #1e1e1e;
  font-weight: 600;
}

.FinancingGrowth {
  margin: 5px;
}

.activityLabel {
  font-family: Inter !important;
  font-size: 14px;
  font-weight: 600;
  color: #1e1e1e;
  padding: 15px 0px 5px 0px;
}

.leftNavOptions {
  box-shadow: 0px 4px 25px 0px #0000000f;
}

.activity-content {
  // background-color: red;
  @media (max-width: 1117px) {
    display: block !important;
  }
}

.activity-content-observations {
  // background-color: yellow;
  // width: 35%!important;
  @media (min-width: 1117px) {
    margin-top: 15px !important;
  }
}

// .activity-content-graph-data {
//   @media (max-width: 1006px) {
//     width: 98% !important;
//     display: block !important;
//     // overflow-y: scroll;
//   }
// }

// .activity-content-graph-registration {
//   @media (max-width: 1006px) {
//     top: 127px !important;
//   }
// }

.industry-welcome {
  background-color: white;
  color: rgb(93, 93, 93);
  border-radius: 8px;
  z-index: 100;
  padding: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.welcome-sylvr {
  padding: 5px 15px;
  font-family: Inter !important;
  font-size: 16px;
  font-weight: 700;
}
.btn-block {
  justify-content: space-around;
  display: flex;
  padding: 15px 15px 5px;
}

.btn-later {
  font-weight: 600;
  font-size: 14px;
  background-color: #e6e6e6;
  margin: 2px 10px 8px;
  color: black;
  border-radius: 5px;
  padding: 2px 20px;
  cursor: pointer;
}

.btn-start-tour {
  font-weight: 600;
  font-size: 14px;
  background-color: #5d5d5d;
  margin: 2px 10px 8px;
  color: white;
  border-radius: 5px;
  padding: 2px 20px;
  cursor: pointer;
}

.heading-tooplip::before {
  content: "";
  position: absolute;
  margin: 0px 0px 0px -24px;
  border-bottom: solid 28px transparent;
  border-right: solid 30px #5d5d5d;
}

.add-big-picture {
  background-color: rgb(93, 93, 93);
  color: white;
  border-radius: 8px;
  z-index: 100;
  margin-left: 18px;
  margin-top: 60px;
}

.add-client {
  padding: 0px 0px 0px 5px;
  color: white;
  font-weight: 600;
}

.single-dashboard {
  padding: 5px 15px;
  font-family: Inter !important;
  font-size: 14px;
  font-weight: 400;
}

.next-block {
  justify-content: flex-end;
  display: flex;
}

.next-btn {
  background-color: white;
  width: fit-content;
  margin: 2px 10px 8px;
  color: black;
  border-radius: 5px;
  padding: 2px 20px;
  cursor: pointer;
}

.add-segments-picture {
  background-color: rgb(93, 93, 93);
  color: white;
  border-radius: 8px;
  z-index: 100;
  position: absolute;
}
.trends-position {
  margin-left: 100px;
  margin-top: calc(
    90vh - 209px - var(--headerbar-height) - var(--footerbar-height)
  );
  min-width: 275px;
  max-width: 375px;
}
.trends-tooplip::before {
  content: "";
  position: absolute;
  border-bottom: solid 20px rgb(93, 93, 93);
  border-left: solid 17px transparent;
  border-right: solid 17px transparent;
  margin-left: 50%;
  margin-top: -20px;
}

.trends-bar {
  height: 5px;
  border-radius: 5px;
  background-color: white;
  margin: 15px;
}
.trends-bar-fill {
  height: 100%;
  width: 20%;
  border-radius: 5px;
  background-color: #6e68de;
}

.observations-position {
  margin-left: 18px;
  margin-top: calc(
    70vh - 100px - var(--headerbar-height) - var(--footerbar-height)
  );
  min-width: 275px;
  max-width: 375px;
}

.observations-tooplip::before {
  content: "";
  position: absolute;
  margin: -15px 0 0 150px;
  border-bottom: solid 20px #5d5d5d;
  border-left: solid 17px transparent;
  border-right: solid 17px transparent;
}

.observations-bar-fill {
  height: 100%;
  width: 40%;
  border-radius: 5px;
  background-color: #6e68de;
}

.advisory-position {
  margin-left: 18px;
  margin-top: calc(
    70vh - 250px - var(--headerbar-height) - var(--footerbar-height)
  );
  min-width: 350px;
  max-width: 350px;
}

.advisory-tooplip::before {
  content: "";
  position: absolute;
  margin: 140px 0 0 150px;
  border-top: solid 30px #5d5d5d;
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}
.advisory-bar-fill {
  height: 100%;
  width: 60%;
  border-radius: 5px;
  background-color: #6e68de;
}

.financial_growth-tooplip::before {
  content: "";
  position: absolute;
  margin: 0px 0px 0px -24px;
  border-bottom: solid 28px transparent;
  border-right: solid 30px #5d5d5d;
}

.financial_growth-position {
  margin-left: -5px;
  min-width: 275px;
  max-width: 375px;
  margin-top: -16px;
}

.benchmark_now-tooplip::before {
  content: "";
  position: absolute;
  margin: 0px 0px 0px 370px;
  border-bottom: solid 30px transparent;
  border-right: solid 30px transparent;
  border-top: solid 30px #5d5d5d;
}

.benchmark_now-position {
  margin-left: -380px;
  min-width: 275px;
  max-width: 375px;
  margin-top: 38px;
}

.benchmark_now-bar-fill {
  height: 100%;
  width: 80%;
  border-radius: 5px;
  background-color: #6e68de;
}

.show-zindex {
  background-color: white;
  z-index: 99;
}

.metric-arrow {
  width: 10px;
  height: 5px;
}

.metric-bottomBorderRadius {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sylvr-title-menu {
  font-size: var(--sylvr-subtitle-fs);
  font-weight: 600;
  text-align: left;
}

.sylvr-subtitle-menu {
  padding: 8px;
  font-size: var(--sylvr-subtitle-fs);
  font-weight: 600;
  text-align: left;
}

.sylvr-subtitle-menu-card {
  margin: 0px;
  width: 100%;
  padding: 3px;
  background-color: white;
  .sylvr-subtitle-label {
    padding: 8px;
    font-size: var(--sylvr-regular-text-fs);
    font-weight: 400;
    text-align: left;
    border-radius: 8px;
  }
}

.segment_risks_block {
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  // min-height: calc(100% - var(--sylvr-footerbar-height));
  // max-height: calc(100% - var(--sylvr-footerbar-height));
  #activityLabel {
    font-family: Inter !important;
    font-size: 14px;
    font-weight: 600;
    color: #1e1e1e;
    // padding: 15px 0px 5px 0px;
    padding: 5px 0px 12px 10px;
    height: 38px;
  }
}

[data-isautherised="false"] {
  top: 0px;
  position: absolute;
}

.segment-top {
  top: -79px;
}
.observations-block {
  position: absolute;
  top: 0%;
  height: 50%;
  width: 100%;
}

.advisory-note-block {
  position: absolute;
  top: 51%;
  width: 100%;
  height: 50%;
}
