.box-cookies{
    z-index: 1000;
    padding: 5px;
    border-radius: 5px;
    position: fixed;
    margin: 15px;
    background-color: #8F8F8F;
    bottom: 0px;
    color: white;
    text-align: center;
    width: 97%;
}