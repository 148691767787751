.left-side-nav {
  // height: calc(100vh - var(--headerbar-height) - var(--footerbar-height));
  min-width: 50px;
  max-width: 50px;
  // @media (max-width: 525px) {
  //   display: none !important;
  // }
}

.industry-block-icon {
  width: 39px;
  height: 39px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin: 5px !important;
  cursor: pointer;
  .industry-icon {
    width: 17px;
    height: 23px;
    border-radius: 4px;
  }
}
