.verticalbar {
  padding: 0px 5px;
}
.verticalbar::after {
  content: "";
  background-color: #000;
  position: absolute;
  width: 1px;
  height: 34px;
  display: block;
}

.observation-heading {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  .observation-label {
    background-color: var(--sylvr-observation-bg-color) !important;
    padding: 4px 8px;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: 100%;
    text-align: center;
    margin: 0px 5px;
  }
}

#segment_risks_observations {
  width: 40%;
  height: calc(
    100vh - 143px - var(--headerbar-height) - var(--footerbar-height)
  ) !important;
  position: relative;
  #segment_risks_observations_content {
    padding: 2px;
    // margin-top: -60px !important;
    height: 100%;
    background-color: #f8fdfd !important;
    #observations_container {
      width: 100%;
      height: 100%;
      border-width: 1px;
      border-radius: 8px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      #observations_container_label {
        text-align: left;
        color: var(--sylvr-text-color-selected) !important;
        font-weight: var(--sylvr-h3-fw) !important;
        font-size: var(--sylvr-h3-fs) !important;
        padding: 2px 8px;
        margin-bottom: 2px;
      }
      #observations_content {
        padding: 2px;
        border-width: 2px;
        border-color: #e6e6e6;
        border-radius: 4px;
        height: calc(
          100% + 30px - var(--headerbar-height) - var(--footerbar-height)
        ) !important;
        overflow-y: auto;
        margin: 0px 5px;
      }
    }
  }

  #segment_risks_advisory_content {
    padding: 2px;
    height: 98%;
    width: 100%;
    // background-color: #f8f8f8;
    #observations_container {
      width: 100%;
      height: 100%;
      border-width: 1px;
      border-radius: 8px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      #observations_container_label {
        text-align: left;
        color: var(--sylvr-text-color-selected) !important;
        font-weight: var(--sylvr-h3-fw) !important;
        font-size: var(--sylvr-h3-fs) !important;
        padding: 2px 8px;
        margin-bottom: 2px;
      }
      #observations_content {
        padding: 2px;
        border-width: 2px;
        border-color: #e6e6e6;
        border-radius: 4px;
        height: calc(
          70vh - 177px - var(--headerbar-height) - var(--footerbar-height)
        );
        overflow: auto;
      }
    }
  }
  @media (max-width: 991px) {
    width: 100%;
  }
}

#observations_unlock {
  padding: 5px;
  z-index: 1;
  margin: 2px 10px;
  height: 60px;
  position: relative;
  top: calc(100% - 20px - var(--headerbar-height) - var(--footerbar-height));
}
