.payment-title {
  text-align: center;
  padding: 20px 20px 10px 20px;
  margin: 0px 15px;
  font-size: 20px;
  font-weight: 700;
  color: #1e1e1e;
}

.payment-early-bird {
  justify-content: center;
  margin: 5px;

  .payment-early-bird-image {
    margin: 0px 5px;
  }
  .payment-early-bird-text {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 600;
  }
  .payment-early-bird-link {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 600;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    cursor: pointer;
  }
}

.close-btn {
  right: -15px;
  position: absolute;
  top: -15px;
  border-radius: 50px;
  box-shadow: -4px -4px 18px rgba(0, 0, 0, 0.4);
  height: 42px;
  width: 42px;
  background: #fff;
}

.plan-block {
  margin: 5px 15px;
  @media (max-width: 500px) {
    display: inline-flex;
    flex-flow: column;
    margin: 5px;
  }

  .plan-container {
    min-width: 271px;
    margin: 0px 5px;
    border-radius: 12px;
    box-shadow: 0px 0px 25px 0px #0000000f;
    @media (max-width: 500px) {
      margin: 5px;
    }
    .plan-content {
      padding: 5px 10px;
    }
  }

  .plan-title {
    font-size: 16px;
    font-weight: 600;
    padding: 7px;
  }
}

.earlyBird{
    position: relative;
    width: 40% !important;
    @media (max-width: 500px) {
      width: 80% !important;
    }
}
