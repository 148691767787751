.verticalbar {
  padding: 0px 5px;
}
.verticalbar::after {
  content: "";
  background-color: #000;
  position: absolute;
  width: 1px;
  height: 34px;
  display: block;
}

.observation-heading {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  justify-content: space-between;
  .observation-label {
    background-color: #f8f8f8;
    padding: 4px 8px;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    margin: 0px 5px;
  }
}

.add_advisory {
  height: 24px;
  width: 24px;
  cursor: pointer;
  padding: 5px;
}

.other_add_advisory {
  cursor: pointer;
  padding: 5px;
}

.advisory_text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: justify;
}

.advisory_action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
