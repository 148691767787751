.loginform{
    padding: 5px;
}

.forgot-signup-block {
  justify-content: space-between;
  margin: 15px 5px;
  .forgot-block{ 
    color: #dc3545;
  }
}