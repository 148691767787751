.sidebar-btn{
  position: fixed !important;
  top: 8% !important;
  border-radius: 0 3px 3px 0 !important;
  // background: #000 !important;
  background: #F8F8F8!important;
  left: 0;
  color: #30d5c8!important;
}

.left-menu .chakra-link{
  margin-top: 0 !important;
}

.main-agency-tab{
  padding-top: 15px;
}
.main-agency-tab .chakra-tabs__tablist{
  border: none !important;
}

.main-agency-tab .chakra-tabs__tab{
  border-radius: 12px 12px 0px 0px;
  background: #F8F8F8;
  padding: 5px 30px;
  color: #8F8F8F;
  font-size: var(--sub-nav);
  font-weight: 600;
  margin-right:25px;
  border: none !important;
}
.main-agency-tab .css-1mreahs[aria-selected=true], .main-agency-tab .css-6z8agw[aria-selected="true"]{
  border: none !important;
  border-radius: 12px 12px 0px 0px;
  background: #FFF;
  box-shadow: 0px -4px 4px 2px rgba(0, 0, 0, 0.08);
  color: #1E1E1E;
}

@media(max-width:575px){
  .main-agency-tab .chakra-tabs__tab{
    padding: 5px 10px;
    font-size: 14px;
    margin-right:20px;
  }
}