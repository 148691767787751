
.row-fluid {
    width: 100%;
    *zoom: 1;
}

.row-fluid:before, .row-fluid:after {
    display: table;
    content: "";
}

.row-fluid:after {
    clear: both;
}

.row-fluid [class*="span"] {
    display: block;
    float: left;
    width: 100%;
    min-height: 1px;
    margin-left: 2.127659574%;
    *margin-left: 2.0744680846382977%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.row-fluid [class*="span"]:first-child {
    margin-left: 0;
}

.row-fluid .span12 {
    width: 99.99999998999999%;
    *width: 99.94680850063828%;
}

.row-fluid .span11 {
    width: 91.489361693%;
    *width: 91.4361702036383%;
}

.row-fluid .span10 {
    width: 82.97872339599999%;
    *width: 82.92553190663828%;
}

.row-fluid .span9 {
    width: 74.468085099%;
    *width: 74.4148936096383%;
}

.row-fluid .span8 {
    width: 65.95744680199999%;
    *width: 65.90425531263828%;
}

.row-fluid .span7 {
    width: 57.446808505%;
    *width: 57.3936170156383%;
}

.row-fluid .span6 {
    width: 48.93617020799999%;
    *width: 48.88297871863829%;
}

.row-fluid .span5 {
    width: 40.425531911%;
    *width: 40.3723404216383%;
}

.row-fluid .span4 {
    width: 31.914893614%;
    *width: 31.8617021246383%;
}

.row-fluid .span3 {
    width: 23.404255317%;
    *width: 23.3510638276383%;
}

.row-fluid .span2 {
    width: 14.89361702%;
    *width: 14.8404255306383%;
}

.row-fluid .span1 {
    width: 6.382978723%;
    *width: 6.329787233638298%;
}

.container-fluid {
    *zoom: 1;
}

.container-fluid:before, .container-fluid:after {
    display: table;
    content: "";
}

.container-fluid:after {
    clear: both;
}

@media (max-width: 767px) {
    .row-fluid {
        width: 100%;
    }

    .row-fluid [class*="span"] {
        display: block;
        float: none;
        width: auto;
        margin-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .row-fluid {
        width: 100%;
        *zoom: 1;
    }

    .row-fluid:before, .row-fluid:after {
        display: table;
        content: "";
    }

    .row-fluid:after {
        clear: both;
    }

    .row-fluid [class*="span"] {
        display: block;
        float: left;
        width: 100%;
        min-height: 1px;
        margin-left: 2.762430939%;
        *margin-left: 2.709239449638298%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
    }

    .row-fluid [class*="span"]:first-child {
        margin-left: 0;
    }

    .row-fluid .span12 {
        width: 99.999999993%;
        *width: 99.9468085036383%;
    }

    .row-fluid .span11 {
        width: 91.436464082%;
        *width: 91.38327259263829%;
    }

    .row-fluid .span10 {
        width: 82.87292817100001%;
        *width: 82.8197366816383%;
    }

    .row-fluid .span9 {
        width: 74.30939226%;
        *width: 74.25620077063829%;
    }

    .row-fluid .span8 {
        width: 65.74585634900001%;
        *width: 65.6926648596383%;
    }

    .row-fluid .span7 {
        width: 57.182320438000005%;
        *width: 57.129128948638304%;
    }

    .row-fluid .span6 {
        width: 48.618784527%;
        *width: 48.5655930376383%;
    }

    .row-fluid .span5 {
        width: 40.055248616%;
        *width: 40.0020571266383%;
    }

    .row-fluid .span4 {
        width: 31.491712705%;
        *width: 31.4385212156383%;
    }

    .row-fluid .span3 {
        width: 22.928176794%;
        *width: 22.874985304638297%;
    }

    .row-fluid .span2 {
        width: 14.364640883%;
        *width: 14.311449393638298%;
    }

    .row-fluid .span1 {
        width: 5.801104972%;
        *width: 5.747913482638298%;
    }
}

@media (min-width: 1280px) {
    .row-fluid {
        width: 100%;
        *zoom: 1;
    }

    .row-fluid:before, .row-fluid:after {
        display: table;
        content: "";
    }

    .row-fluid:after {
        clear: both;
    }

    .row-fluid [class*="span"] {
        display: block;
        float: left;
        width: 100%;
        min-height: 1px;
        margin-left: 2.564102564%;
        *margin-left: 2.510911074638298%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
    }

    .row-fluid [class*="span"]:first-child {
        margin-left: 0;
    }

    .row-fluid .span12 {
        width: 100%;
        *width: 99.94680851063829%;
    }

    .row-fluid .span11 {
        width: 91.45299145300001%;
        *width: 91.3997999636383%;
    }

    .row-fluid .span10 {
        width: 82.905982906%;
        *width: 82.8527914166383%;
    }

    .row-fluid .span9 {
        width: 74.358974359%;
        *width: 74.30578286963829%;
    }

    .row-fluid .span8 {
        width: 65.81196581200001%;
        *width: 65.7587743226383%;
    }

    .row-fluid .span7 {
        width: 57.264957265%;
        *width: 57.2117657756383%;
    }

    .row-fluid .span6 {
        width: 48.717948718%;
        *width: 48.6647572286383%;
    }

    .row-fluid .span5 {
        width: 40.170940171000005%;
        *width: 40.117748681638304%;
    }

    .row-fluid .span4 {
        width: 31.623931624%;
        *width: 31.5707401346383%;
    }

    .row-fluid .span3 {
        width: 23.076923077%;
        *width: 23.0237315876383%;
    }

    .row-fluid .span2 {
        width: 14.529914530000001%;
        *width: 14.4767230406383%;
    }

    .row-fluid .span1 {
        width: 5.982905983%;
        *width: 5.929714493638298%;
    }
}

.hide {
    display: none;
}

.show {
    display: block;
}

.invisible {
    visibility: hidden;
}

.hidden {
    display: none;
    visibility: hidden;
}

/* Responsive Visibilty Classes */

.visible-phone {
    display: none !important;
}

.visible-tablet {
    display: none !important;
}

.hidden-desktop {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-phone {
        display: inherit !important;
    }

    .hidden-phone {
        display: none !important;
    }

    .hidden-desktop {
        display: inherit !important;
    }

    .visible-desktop {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .visible-tablet {
        display: inherit !important;
    }

    .hidden-tablet {
        display: none !important;
    }

    .hidden-desktop {
        display: inherit !important;
    }

    .visible-desktop {
        display: none !important ;
    }
}
.content-wrapper {
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 1380px) {
  .content-wrapper {
    padding: 0;
  }
}

.dnd-section > .row-fluid {
  margin: 0 auto;
}

.dnd-section .dnd-column {
  padding: 0;
}

@media (max-width: 767px) {
  .dnd-section .dnd-column {
    padding: 0;
  }
}

/* Elements
Base HMTL elements are styled in this section (<body<, <h1>, <a>, <p>, <button> etc.)
*/

/* Paragraphs */

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Anchors */

/* Headings */


/* Abbr */

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

/* Address */

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

/* Lists */

ol,
ul {
  padding-left: 2rem;
  list-style-position: inside;
}
 
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

/* Code blocks */

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

/* Blockquotes */

blockquote {
  border-left: 4px solid;
  margin: 0 0 1rem;
  padding-left: 15px;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

/* Horizontal rules */

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: #CCC;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

/* Image alt text */

img {
  font-size: 14px;
  word-break: normal;
  max-width: 100%;
  height: auto;
}

img,
svg {
  vertical-align: middle;
}

/* Table */

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
button,
.button,
.button-outline,
.cta_button {
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  text-align: center;
  transition: all 0.15s linear;
  white-space: normal;
}

button:disabled,
.button:disabled,
.button-outline:disabled,
.cta_button:disabled {
  background-color: #D0D0D0;
  border-color: #D0D0D0;
  color: #E6E6E6;
}

button:hover,
button:focus,
.button:hover,
.button:focus,
.button-outline:hover,
.button-outline:focus,
.cta_button:hover,
.cta_button:focus {
  text-decoration: none;
}

button:active,
.button:active,
.button-outline:active,
.cta_button:active {
  text-decoration: none;
}
/* Form fields */

.hs-form-field {
  margin-bottom: 1.4rem;
}

/* Labels */

form label {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.35rem;
}

/* Help text - legends */

form legend {
  font-size: 0.875rem;
}

/* Inputs */

form input[type=text],
form input[type=email],
form input[type=password],
form input[type=tel],
form input[type=number],
form input[type=file],
form input[type=search],
form select,
form textarea {
  display: block;
  width: 100%;
  padding: 10px 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  height: auto !important;
  margin-top: 0 !important;
}

form select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

form textarea {
  min-height: 150px;
}

#email-prefs-form input[type=email] {
  font-size: 16px !important;
  padding: 12px 12px !important;
  width: 100% !important;
  margin-bottom: 20px;
}

form fieldset {
  max-width: 100% !important;
}

/* Inputs - checkbox/radio */

form .inputs-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

form .inputs-list > li {
  display: block;
  margin: 0.7rem 0;
}

form .inputs-list input,
form .inputs-list span {
  vertical-align: middle;
}

form input[type=checkbox],
form input[type=radio] {
  cursor: pointer;
  margin-right: 0.35rem;
}

/* Inputs - datepicker */

.hs-dateinput {
  position: relative;
}

.hs-dateinput:before {
  content:'\01F4C5';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.fn-date-picker .pika-table thead th {
  color: #FFF;
}

.fn-date-picker td.is-selected .pika-button {
  border-radius: 0;
  box-shadow: none;
}

.fn-date-picker td .pika-button:hover,
.fn-date-picker td .pika-button:focus {
  border-radius: 0 !important;
  color: #FFF;
}

/* Inputs - file picker */

form input[type=file] {
  background-color: transparent;
  border: initial;
  padding: initial;
}

/* Headings and text */

form .hs-richtext,
form .hs-richtext p {
  font-size: 0.875rem;
  margin: 0 0 1.4rem;
}

form .hs-richtext img {
  max-width: 100% !important;
}

/* GDPR */

.legal-consent-container .hs-form-booleancheckbox-display > span,
.legal-consent-container .hs-form-booleancheckbox-display > span p {
  margin-left: 1rem !important;
}

/* Validation */

.hs-form-required {
  color: #EF6B51;
}

.hs-input.invalid.error {
  border-color: #EF6B51;
}

.hs-error-msg {
  color: #EF6B51;
  margin-top: 0.35rem;
}

/* Submit button */

form input[type=submit],
form .hs-button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  white-space: normal;
}

form input[type=submit]:hover,
form .hs-button:hover {}

/* Captcha */

.grecaptcha-badge {
  margin: 0 auto;
}
/* Table */

table {
  border: 1px solid;
  margin-bottom: 1.4rem;
  overflow-wrap: break-word;
}

/* Table Cells */

th,
td {
  border: 1px solid;
  padding: 0.75rem;
  vertical-align: top;
}

/* Table Header */

thead th,
thead td {
  border-bottom: 2px solid;
  vertical-align: bottom;
}

/* Components
Specific pieces of UI that are stylized. Typically used for global partial styling
*/

/* Header DND sections */

.header .dnd-section {
  padding: 0;
}

/* Header container */

.header__row-1 {
  padding: 10px 0;
}
 
.header__row-1,
.header__row-2 {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

@media (max-width: 1280px) and (min-width: 1139px) {
  .header__column {
    width: 100%;
  }
}

@media (max-width: 1139px) {
  
  .header__logo--main { 
    padding-top: 0 !important;
  }
  
  .header__container {
    flex-direction: row;

    align-items: center;
    position: relative;
  }

  

  .header__row-1 {
    padding-top: 0;
  }

  .header__row-2 {
    justify-content: center;
    /* padding: 30px; */
  }
  


}

/* Navigation skipper */

.header__skip {
  height: 1px;
  left: -1000px;
  overflow: hidden;
  position: absolute;
  text-align: left;
  top: -1000px;
  width: 1px;
}

.header__skip:hover,
.header__skip:focus,
.header__skip:active {
  height: auto;
  left: 0;
  overflow: visible;
  top: 0;
  width: auto;
}

/* Logo */

.header__logo {
  align-items: center;
  display: flex;
  height: auto;
  margin-right: 20px;
  max-width: 200px;
  overflow: hidden;
}

.header__container-lp .header__logo{
  margin-left: auto;
  margin-right: auto;
}

.header__logo img {
  max-width: 100%;
  vertical-align: middle;
}

.header__logo .logo-company-name {
  font-size: 28px;
  margin-top: 7px;
}

/* Search bar */

.header__search {
  padding: 0;
  width: auto;
}

.header__search .hs-search-field__input {
  background-image: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/search-icon-18.svg);
  background-position: center right 15px;
  background-repeat: no-repeat;

}

.header__search .hs-search-field--open .hs-search-field__input {
  border-bottom: none;
  border-radius: 6px 6px 0 0;
  max-width: 100%;
}

.header__search .hs-search-field--open .hs-search-field__suggestions {
  background-color: #FFF;
  border: 2px solid #D1D6DC;
  border-radius: 0 0 6px 6px;
  border-top-width: 1px;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.header__search .hs-search-field__suggestions li {
  border-top: 1px solid #D1D6DC;
  font-size: 0.875rem;
}

.header__search .hs-search-field__suggestions li a {
  color: #494A52;
  padding: 10px 15px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.header__search .hs-search-field__suggestions #results-for {
  display: none;
}

@media (min-width: 1139px) {
  
  .header__search form {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .header__search label {
    margin: 0 20px 0 0;
  }

  .header__search .hs-search-field__input {
    width: auto;
  }
  
}

@media (max-width: 1139px) {
  .header__search {
    border-top: 2px solid #CED4DB;
    order: 1;
    padding: 20px;
  }
  
  .header__logo {
    margin-right: auto;  
  }
}

/* Language switcher */

.header__language-switcher {
  cursor: pointer;
  padding-right: 0;
  text-align: right;
  display: inline-block;
}

.header__language-switcher .lang_switcher_class {
  position: static;
  vertical-align: middle;
}

.header__language-switcher .lang_list_class {
  border: 1px solid;
  border-radius: 6px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2);
  display: block;
  /* left: calc(100% - 24px); */
  opacity: 0;
  min-width: 100px;
  padding-top: 0;
  text-align: left;
  top: 100%;
  transition: opacity 0.3s;
  visibility: hidden;
}

.header__language-switcher:hover .lang_list_class,
.header__language-switcher:focus .lang_list_class {
  opacity: 1;
  transition: opacity 0.3s;
  visibility: visible;
}

.header__language-switcher .lang_list_class:before {
  left: 70%;
  top: -25px;
}

.header__language-switcher .lang_list_class:after {
  left: 70%;
  top: -22px;
}

.header__language-switcher .lang_list_class:before,
.header__language-switcher .lang_list_class:after {
  content: none;
}

.header__language-switcher .lang_list_class.first-active::after {
  top: -22px;
  transition: 0.3s;
}

.header__language-switcher .lang_list_class li {
  border: none;
  font-size: 14px;
  padding: 10px 15px;
}

.header__language-switcher .lang_list_class li:first-child {
  border-radius: 6px 6px 0 0;
  border-top: none;
}

.header__language-switcher .lang_list_class li:last-child {
  border-bottom: none;
  border-radius: 0 0 6px 6px;
}

.header__language-switcher .lang_list_class li:hover {
  background-color: #EBEFF3;
  transition: background-color 0.3s;
}

.header__language-switcher--label {
  position: relative;
  display: inline-block;
}

.widget-type-language_switcher {
  display: inline-block;
}

.info-text {
  display: inline-block;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #fff;
  font-size: 14px;
}

.header__language-switcher--label-current {
  align-items: center;
  display: flex;
  font-size: 16px;
  margin-bottom: 0;
  margin-left: 10px;
  display: inline-block;
}

.header__language-switcher--label-current:after {
  background: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/angle-down.svg);
  background-size: cover;
  width: 15px;
  height: 15px;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
}

body .globe_class {
  background-image: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/globe-dark.svg);
  width: 18px;
  height: 18px;
}

@media (max-width: 1139px) {
  .header__language-switcher {
    border-top: 2px solid #CED4DB;
    padding-left: 20px;
    padding-right: 20px;
  }

  .header__language-switcher .lang_list_class {
    border: none;
    box-shadow: unset;
    display: block;
    left: auto;
    right: 0;
    opacity: 1;
    padding: 0 20px;
    top: 0;
    visibility: visible;
    transform: none;
    border-radius: 0;
  }

  .header__language-switcher .lang_list_class li {
    background-color: inherit;
    font-size: 22px;
  }

  .header__language-switcher--label-current {
    display: none;
  }

  .header__language-switcher .globe_class {
    background-image: none;
  }

  .header__language-switcher .lang_list_class li:hover{
    background-color: inherit;
  }

  .header__language-switcher .lang_list_class:before,
  .header__language-switcher .lang_list_class:after {
    content: none;
  }
}

/* Navigation */

#nav-toggle {
  display: none;
}

/* Mobile toggles */

@media (max-width: 1139px) {
  .header__navigation,
  .header__search,
  .header__language-switcher {
    display: none;
    width: 100%;
  }

  .header__navigation.open,
  .header__search.open,
  .header__language-switcher.open {
    background-color: #F8FAFC;
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 2;
  }
  
  .header__language-switcher.open {
    height: 0;
  }

  .header__navigation--toggle,
  .header__search--toggle,
  .header__language-switcher--toggle,
  .header__close--toggle {
    cursor: pointer;
    /* margin: 0 5vw; */
    position: relative;
  }
  
  .header__navigation--toggle,
  .header__language-switcher--toggle {
    margin-right: 15px;
  }

  .header__navigation--toggle.hide.open,
  .header__search--toggle.hide,
  .header__language-switcher--toggle.hide {
    display: none;
  }

  .header__navigation--toggle.open,
  .header__search--toggle.open,
  .header__language-switcher--toggle.open {
    display: block;
    margin-left: 0;
    margin-right: 15px;
  }

  .header__navigation--toggle:after,
  .header__search--toggle:after,
  .header__language-switcher--toggle:after {
    display: none;
    font-size: 26px;
    font-weight: 600;
    position: absolute;
    left: 40px;
    text-transform: uppercase;
    top: -10px;
  }

  .header__navigation--toggle.open:after,
  .header__search--toggle.open:after,
  .header__language-switcher--toggle.open:after {
    display: block;
    word-break: normal;
  }

  .header__navigation--toggle {
    background-image: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/bars.svg);
    background-size: cover;
    height: 25px;
    width: 25px;
  }

  .header__navigation--toggle:after {
    content: none;
  }

  .header__language-switcher--toggle {
    background-image: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/globe-dark.svg);
    background-size: cover;
    height: 25px;
    width: 25px;
  }

  .header__language-switcher--toggle:after {
    content: none;
  }

  .header__search--toggle {
    background-image: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/search-dark.svg);
    background-size: cover;
    height: 25px;
    width: 25px;
  }

  .header__search--toggle:after {
    content: none;
  }

  .header__close--toggle {
    background-image: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/times-dark.svg);
    background-repeat: no-repeat;
    background-size: 110%;
    display: none;
    height: 25px;
    margin-right: 0;
    width: 25px;
  }

  .header__close--toggle.show {
    display: block;
  }
}
/* Menu and simple menu */

.hs-menu-wrapper ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

/* Horizontal menu */

.hs-menu-wrapper.hs-menu-flow-horizontal .hs-menu-children-wrapper {
  flex-direction: column;
}

@media (max-width: 767px) {
  .hs-menu-wrapper.hs-menu-flow-horizontal ul {
    flex-direction: column;
  }
}

/* Vertical menu */

.hs-menu-wrapper.hs-menu-flow-vertical ul {
  flex-direction: column;
}

/* Flyouts */

.hs-menu-wrapper.hs-menu-flow-vertical.flyouts ul {
  display: inline-flex;
}

@media (max-width: 767px) {
  .hs-menu-wrapper.hs-menu-flow-vertical ul {
    display: flex;
  }
}

.hs-menu-wrapper.flyouts .hs-item-has-children {
  position: relative;
}

.hs-menu-wrapper.flyouts .hs-menu-children-wrapper {
  left: -9999px;
  opacity: 0;
  position: absolute;
}

.hs-menu-wrapper.flyouts .hs-menu-children-wrapper a {
  display: block;
  white-space: nowrap;
}

.hs-menu-wrapper.hs-menu-flow-horizontal.flyouts .hs-item-has-children:hover > .hs-menu-children-wrapper {
  left: 0;
  opacity: 1;
  top: 100%;
}

.hs-menu-wrapper.hs-menu-flow-vertical.flyouts .hs-item-has-children:hover > .hs-menu-children-wrapper {
  left: 100%;
  opacity: 1;
  top: 0;
}

@media (max-width: 767px) {
  .hs-menu-wrapper.flyouts .hs-menu-children-wrapper,
  .hs-menu-wrapper.hs-menu-flow-horizontal.flyouts .hs-item-has-children:hover > .hs-menu-children-wrapper,
  .hs-menu-wrapper.hs-menu-flow-vertical.flyouts .hs-item-has-children:hover > .hs-menu-children-wrapper {
    left: 0;
    opacity: 1;
    position: relative;
    top: auto;
  }
}

/* CTA, logo, and rich text images */

.hs_cos_wrapper_type_cta img,
.hs_cos_wrapper_type_logo img,
.hs_cos_wrapper_type_rich_text img {
  height: auto;
  max-width: 100%;
}

.hs_cos_wrapper_type_rich_text img ,
.hs_cos_wrapper_type_linked_image img{
  border-radius: 20px;
}

/* Utilities
Helper classes with ability to override anything that comes before it
*/

/* For content that needs to be visually hidden but stay visible for screenreaders */

.show-for-sr {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

@media (max-width: 767px) {
  .show-for-sr--mobile {
    border: 0 !important;
    clip: rect(0, 0, 0, 0) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
  }
}