
      a.cta_button {
        -moz-box-sizing: content-box !important;
        -webkit-box-sizing: content-box !important;
        box-sizing: content-box !important;
        vertical-align: middle;
      }
      .hs-breadcrumb-menu {
        list-style-type: none;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
      }
      .hs-breadcrumb-menu-item {
        float: left;
        padding: 10px 0px 10px 10px;
      }
      .hs-breadcrumb-menu-divider:before {
        content: "›";
        padding-left: 10px;
      }
      .hs-featured-image-link {
        border: 0;
      }
      .hs-featured-image {
        float: right;
        margin: 0 0 20px 20px;
        max-width: 50%;
      }
      @media (max-width: 568px) {
        .hs-featured-image {
          float: none;
          margin: 0;
          width: 100%;
          max-width: 100%;
        }
      }
      .hs-screen-reader-text {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
      }

#hs_cos_wrapper_widget_1707281327040
        .th-hero-2
        .heading-text
        .heading-color {
        color: rgba(250, 250, 250, 1);
      }

      #hs_cos_wrapper_widget_1707281327040
        .th-hero-2
        .heading-text
        .heading-color
        strong {
        color: #30d5c8;
      }

      #hs_cos_wrapper_widget_1707281327040 .button-1 .button,
      #hs_cos_wrapper_widget_1707281327040 .button-1 .cta_button {
        background-color: rgba(255, 255, 255, 0);
        // border-color: rgba(#null, 0);
        color: rgba(48, 213, 200, 1);
        fill: rgba(48, 213, 200, 1);
      }

      #hs_cos_wrapper_widget_1707281327040 .button-1 .button:hover,
      #hs_cos_wrapper_widget_1707281327040 .button-1 .button:focus,
      #hs_cos_wrapper_widget_1707281327040 .button-1 .cta_button:hover,
      #hs_cos_wrapper_widget_1707281327040 .button-1 .cta_button:focus {
        background-color: rgba(235, 235, 235, 0);
        // border-color: rgba(#null, 0);
      }

      #hs_cos_wrapper_widget_1707281327040 .button-1 .button:active,
      #hs_cos_wrapper_widget_1707281327040 .button-1 .cta_button:active {
        background-color: rgba(225, 225, 225, 0);
        // border-color: rgba(#null, 0);
      }

 .iframe_wrapper {
    height: 0;
    padding-bottom: 56.25%;
    padding-top: 25px;
    position: relative;
}
.embed_container {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%;
}     
  #hs_cos_wrapper_menu_button .button-1 .button,
#hs_cos_wrapper_menu_button .button-1 .cta_button {
  background-color:rgba(0,0,0,0.01);
  border-color:rgba(48,213,200,1.0);
  color:rgba(48,213,200,1.0);
  fill:rgba(48,213,200,1.0);
}

#hs_cos_wrapper_menu_button .button-1 .button:hover,
#hs_cos_wrapper_menu_button .button-1 .button:focus,
#hs_cos_wrapper_menu_button .button-1 .cta_button:hover,
#hs_cos_wrapper_menu_button .button-1 .cta_button:focus {
  background-color:rgba(0,0,0,0.01);
  border-color:rgba(28,193,180,1.0);
}

#hs_cos_wrapper_menu_button .button-1 .button:active,
#hs_cos_wrapper_menu_button .button-1 .cta_button:active {
  background-color:rgba(0,0,0,0.01);
  border-color:rgba(18,183,170,1.0);
}

#hs_cos_wrapper_menu_button .button-2 .button,
#hs_cos_wrapper_menu_button .button-2 .cta_button {
  background-color:rgba(48,213,200,1.0);
  // border-color:rgba(#null,0.0);
  // color:rgba(#null,0.0);
  // fill:rgba(#null,0.0);
}

#hs_cos_wrapper_menu_button .button-2 .button:hover,
#hs_cos_wrapper_menu_button .button-2 .button:focus,
#hs_cos_wrapper_menu_button .button-2 .cta_button:hover,
#hs_cos_wrapper_menu_button .button-2 .cta_button:focus {
  background-color:rgba(28,193,180,1.0);
  // border-color:rgba(#null,0.0);
}

#hs_cos_wrapper_menu_button .button-2 .button:active,
#hs_cos_wrapper_menu_button .button-2 .cta_button:active {
  background-color:rgba(18,183,170,1.0);
  // border-color:rgba(#null,0.0);
}


#oembed_container-widget_1707281360758 .oembed_custom-thumbnail_icon svg {
      fill: #ffffff;
    }

      #hs_cos_wrapper_module_17103993460624 .th-hero-1 .heading-text .heading-color strong { color:#30d5c8; }

#hs_cos_wrapper_module_17103993460624 .button-1 .button .hs_cos_wrapper_type_icon svg { fill:#ffffff; }

#hs_cos_wrapper_module_17103993460624 .button-2 .button .hs_cos_wrapper_type_icon svg { fill:#ffffff; }

  #hs_cos_wrapper_widget_1710240234504 .th-hero-1 .heading-text .heading-color strong { color:#30d5c8; }

#hs_cos_wrapper_widget_1710240234504 .button-1 .button .hs_cos_wrapper_type_icon svg { fill:#ffffff; }

#hs_cos_wrapper_widget_1710240234504 .button-2 .button .hs_cos_wrapper_type_icon svg { fill:#ffffff; }

  #hs_cos_wrapper_widget_1710240286176 .th-hero-1 .heading-text .heading-color strong { color:#30d5c8; }

#hs_cos_wrapper_widget_1710240286176 .button-1 .button .hs_cos_wrapper_type_icon svg { fill:#ffffff; }

#hs_cos_wrapper_widget_1710240286176 .button-2 .button .hs_cos_wrapper_type_icon svg { fill:#ffffff; }


  #hs_cos_wrapper_dnd_area-module-5 .th-animated-counter .card { background:linear-gradient(to bottom,rgba(231,231,238,1),rgba(231,231,238,1)); }

#hs_cos_wrapper_dnd_area-module-5 .th-animated-counter .heading-text .heading-color strong { color:#30d5c8; }

#hs_cos_wrapper_dnd_area-module-5 .th-animated-counter .card .card-number,
#hs_cos_wrapper_dnd_area-module-5 .th-animated-counter .card .card-number-suffix { color:rgba(28,29,41,1.0); }


  #hs_cos_wrapper_widget_1710322599002 .th-gallery-filter .heading-text .heading-color { color:rgba(48,213,200,1.0); }

#hs_cos_wrapper_widget_1710322599002 .th-gallery-filter .filter-button-plain .radio-inline label {
  font-weight:700;
  font-size:14px;
  color:#1c1d29;
}

#hs_cos_wrapper_widget_1710322599002 .th-gallery-filter .filter-button-plain .radio-inline label.active { color:#425b76; }


 #hs_cos_wrapper_dnd_area-module-7 .th-testimonial .heading-text .heading-color { color:rgba(255,255,255,1.0); }

#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .heading-text .heading-color strong { color:#30d5c8; }

#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .slick-next,
#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .slick-prev { background:#30d5c8; }

#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .testimonial-text * { color:rgba(255,255,255,0.64); }

#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .testimonial-name { color:rgba(255,255,255,1.0); }

#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .testimonial-title { color:rgba(255,106,0,1.0); }


 #hs_cos_wrapper_dnd_area-module-7 .th-testimonial .heading-text .heading-color { color:rgba(255,255,255,1.0); }

#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .heading-text .heading-color strong { color:#30d5c8; }

#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .slick-next,
#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .slick-prev { background:#30d5c8; }

#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .testimonial-text * { color:rgba(255,255,255,0.64); }

#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .testimonial-name { color:rgba(255,255,255,1.0); }

#hs_cos_wrapper_dnd_area-module-7 .th-testimonial .testimonial-title { color:rgba(255,106,0,1.0); }


#hs_cos_wrapper_widget_1707284368209 .hs-tabs-wrapper {}

#hs_cos_wrapper_widget_1707284368209 .hs-tabs__tab {}

#hs_cos_wrapper_widget_1707284368209 .hs-tabs__tab[aria-selected='true'] {
  border-top:1px solid rgba(204,204,204,1.0);
  border-left:1px solid rgba(204,204,204,1.0);
  border-right:1px solid rgba(204,204,204,1.0);
  border-bottom:2px solid rgba(48,213,200,1.0);
  color:#425b76;
  font-style:normal;
  font-weight:bold;
  text-decoration:none;
}

#hs_cos_wrapper_widget_1707284368209 .hs-tabs__tab[aria-selected='true']:after { border-bottom:2px solid rgba(48,213,200,1.0); }

#hs_cos_wrapper_widget_1707284368209 .hs-tabs__tab[aria-selected='false'] {
  border-top:1px solid rgba(204,204,204,1.0);
  border-left:1px solid rgba(204,204,204,1.0);
  border-right:1px solid rgba(204,204,204,1.0);
  border-bottom:2px solid rgba(204,204,204,1.0);
}

#hs_cos_wrapper_widget_1707284368209 .hs-tabs__tab[aria-selected='false']:after { border-bottom:2px solid rgba(204,204,204,1.0); }

#hs_cos_wrapper_widget_1707284368209 .hs-tabs__tab[aria-selected='false']:hover,
#hs_cos_wrapper_widget_1707284368209 .hs-tabs__tab[aria-selected='false']:focus {
  border-top:1px solid rgba(204,204,204,1.0);
  border-left:1px solid rgba(204,204,204,1.0);
  border-right:1px solid rgba(204,204,204,1.0);
  border-bottom:2px solid rgba(66,91,118,1.0);
  color:#425b76;
}

#hs_cos_wrapper_widget_1707284368209 .hs-tabs__tab[aria-selected='false']:hover:after,
#hs_cos_wrapper_widget_1707284368209 .hs-tabs__tab[aria-selected='false']:focus:after { border-bottom:2px solid rgba(66,91,118,1.0); }

#hs_cos_wrapper_widget_1707284368209 .hs-tabs__content { border:1px solid #cccccc; }

#hs_cos_wrapper_widget_1707284368209 .hs-tabs__content__panel {
  margin-right:-100%;
  visibility:hidden;
}

#hs_cos_wrapper_widget_1707284368209 .hs-tabs__content__panel--visible { visibility:visible; }



#hs_cos_wrapper_dnd_area-module-8 .th-card-3 { margin-top:-8em; }

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .heading-text .heading-color strong { color:#30d5c8; }

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card {
  background:linear-gradient(to bottom,rgba(255,255,255,1),rgba(255,255,255,1));
  border:1px solid #f3f3f7;
}

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card:hover { background:#e7e7ee; }

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card:hover { background:#e7e7ee; }

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card:hover { background:#e7e7ee; }

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card:hover { background:#e7e7ee; }

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card .card-icon svg { fill:rgba(255,255,255,1.0); }

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card .card-icon svg:hover,
#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card .card-icon svg:focus { fill:rgba(235,235,235,1.0); }

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card .card-tagline { color:#30d5c8; }

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card .card-share-icon-wrapper { background-color:rgba(255,106,0,1.0); }

#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card .card-share-icon-wrapper:hover,
#hs_cos_wrapper_dnd_area-module-8 .th-card-3 .card .card-share-icon-wrapper:focus { background-color:rgba(235,86,0,1.0); }


  #hs_cos_wrapper_dnd_area-module-14 .th-cta-1 .tagline-wrap .tagline { color:rgba(255,255,255,1.0); }

#hs_cos_wrapper_dnd_area-module-14 .th-cta-1 .tagline-wrap .tagline-line { background-color:rgba(255,255,255,1.0); }

#hs_cos_wrapper_dnd_area-module-14 .th-cta-1 .heading-text .heading-color { color:rgba(255,255,255,1.0); }

#hs_cos_wrapper_dnd_area-module-14 .th-cta-1 .heading-text .heading-color strong { color:#30d5c8; }

#hs_cos_wrapper_dnd_area-module-14 .description-content-wrap { background-color:#f3f3f7; }


#hs_cos_wrapper_widget_1703674221462 .hero-slider-item-1 {}

#hs_cos_wrapper_widget_1703674221462 .hero-slider-item-2 {}

#hs_cos_wrapper_widget_1703674221462 .th-hero-slider .heading-text .heading-color { color:rgba(255,255,255,1.0); }

#hs_cos_wrapper_widget_1703674221462 .th-hero-slider .tagline-wrap .tagline { color:rgba(255,255,255,1.0); }

#hs_cos_wrapper_widget_1703674221462 .th-hero-slider .tagline-wrap .tagline-line { background-color:rgba(255,255,255,1.0); }

#hs_cos_wrapper_widget_1703674221462 .th-hero-slider .slick-arrow svg { fill:rgba(255,255,255,1.0); }

#hs_cos_wrapper_widget_1703674221462 .th-hero-slider .slick-slide { padding-top:0px; }

#hs_cos_wrapper_widget_1703674221462 .th-hero-slider .slick-slide { min-height:450px; }

@media (max-width:1139px) {
  #hs_cos_wrapper_widget_1703674221462 .th-hero-slider .slick-slide { min-height:300px; }

  #hs_cos_wrapper_widget_1703674221462 .th-hero-slider .slick-slide { padding-top:0px; }
}

@media (max-width:767px) {
  #hs_cos_wrapper_widget_1703674221462 .th-hero-slider .slick-slide { min-height:300px; }

  #hs_cos_wrapper_widget_1703674221462 .th-hero-slider .slick-slide { padding-top:0px; }
}



#hs_cos_wrapper_footer-module-15 hr {
  border:0 none;
  border-bottom-width:1px;
  border-bottom-style:solid;
  border-bottom-color:rgba(0,0,0,1.0);
  margin-left:auto;
  margin-right:auto;
  margin-top:10px;
  margin-bottom:10px;
  width:50%;
}


.dnd_area-row-0-background-image {
  background-image: url('https://beta.sylvr.co.in/hubfs/redirect.jpg') !important;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
.dnd_area-row-6-background-color {
  background-color: rgba(28, 29, 41, 1) !important;
}
.dnd_area-row-8-background-color {
  background-color: rgba(243, 243, 247, 1) !important;
}
.dnd_area-row-9-background-color {
  background-color: rgba(28, 29, 41, 1) !important;
}
.footer-row-1-background-gradient {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)) !important;
}
.footer-row-2-background-gradient {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)) !important;
}
/* HubSpot Non-stacked Media Query Styles */
@media (min-width:768px) {
  .footer-row-1-vertical-alignment > .row-fluid {
    display: -ms-flexbox !important;
    -ms-flex-direction: row;
    display: flex !important;
    flex-direction: row;
  }
  .footer-row-2-vertical-alignment > .row-fluid {
    display: -ms-flexbox !important;
    -ms-flex-direction: row;
    display: flex !important;
    flex-direction: row;
  }
  .footer-column-14-vertical-alignment {
    display: -ms-flexbox !important;
    -ms-flex-direction: column !important;
    -ms-flex-pack: center !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .footer-column-14-vertical-alignment > div {
    flex-shrink: 0 !important;
  }
  .footer-column-18-vertical-alignment {
    display: -ms-flexbox !important;
    -ms-flex-direction: column !important;
    -ms-flex-pack: center !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .footer-column-18-vertical-alignment > div {
    flex-shrink: 0 !important;
  }
  .footer-column-18-row-0-vertical-alignment > .row-fluid {
    display: -ms-flexbox !important;
    -ms-flex-direction: row;
    display: flex !important;
    flex-direction: row;
  }
  .footer-module-19-vertical-alignment {
    display: -ms-flexbox !important;
    -ms-flex-direction: column !important;
    -ms-flex-pack: center !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .footer-module-19-vertical-alignment > div {
    flex-shrink: 0 !important;
  }
  .footer-column-16-vertical-alignment {
    display: -ms-flexbox !important;
    -ms-flex-direction: column !important;
    -ms-flex-pack: center !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .footer-column-16-vertical-alignment > div {
    flex-shrink: 0 !important;
  }
}
/* HubSpot Styles (default) */
.dnd_area-row-0-padding {
  padding-bottom: 120px !important;
}
.dnd_area-row-6-padding {
  padding-top: 50px !important;
  padding-bottom: 120px !important;
}
.dnd_area-row-8-hidden {
  display: none !important;
}
.footer-row-0-padding {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.footer-row-1-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.footer-row-2-padding {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.footer-column-4-row-0-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.footer-column-11-row-1-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.footer-column-5-row-1-margin {
  margin-top: 0px !important;
  margin-bottom: 25px !important;
}
.footer-column-1-row-0-margin {
  margin-top: 0px !important;
  margin-bottom: 25px !important;
}
.footer-column-1-row-1-margin {
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}
.footer-column-14-row-0-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.footer-column-18-row-0-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
/* HubSpot Styles (mobile) */
@media (max-width: 1024px) {
  .footer-row-0-padding {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .footer-row-1-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .footer-row-2-padding {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

