.profile-settings {
  padding: 10px 20px;
  .profile-settings-label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #1e1e1e;
    padding: 5px;
  }
  .profile-settings-content {
    .input-box {
      padding: 5px;
      .label {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
      }
      .input {
        height: 30px;
        background-color: #F8F8F8;
      }
    }
  }
}
.profile-company {
  padding: 10px 20px;
  .profile-company-content {
    .input-box {
      padding: 15px 5px;
      .label {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
      }
      .input {
        height: 30px;
        background-color: #F8F8F8;
      }
    }
  }
}

