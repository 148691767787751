.menu-button {
  list-style: none;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 0;
}
.menu-button li {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}
.menu-button li:last-child {
  padding-right: 0;
}
.menu-button li.header-search:last-child {
  padding-left: 0;
  padding-right: 0;
}
.menu-button li:not(:last-child) {
  padding-right: 20px;
}
.menu-button .header-search {
  position: relative;
}
.menu-button .toggle-content,
.menu-button .toggle.toggled .hs_cos_wrapper_type_icon:first-child {
  display: none;
}
.menu-button .toggle .hs_cos_wrapper_type_icon:first-child,
.menu-button .toggle.toggled .hs_cos_wrapper_type_icon:last-child {
  display: block;
}
.menu-button .toggle .hs_cos_wrapper_type_icon:last-child {
  display: none;
}
.menu-button .toggle-content.toggled {
  display: block;
}
.menu-button .hs-search-form {
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgb(0 0 0/5%);
  display: flex;
  margin: 0;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 45px;
  width: 300px;
}
.menu-button .hs-search-input {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: 0;
}
.menu-button .hs-search-form .hs-search-submit {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 7px 15px;
}
.menu-button .submenu.level-1 > li.header-button {
  padding-right: 0;
}
.menu-button .button .hs_cos_wrapper_type_icon,
.menu-button .button-outline .hs_cos_wrapper_type_icon {
  margin-left: 0;
  margin-right: 5px;
}
@media (max-width: 1139px) {
  .menu-button li,
  .menu-button li:first-child {
    margin: 0;
    width: 100%;
  }
  .menu-button li:last-child {
    padding-right: 1.2rem;
  }
  .menu-button > li > a {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .menu-button li {
    margin: 0;
    padding: 1.2rem;
  }
  .menu-button li:last-child {
    padding-right: 1.2rem;
  }
}
