.body-container--blog-post .content-wrapper,
.content-wrapper {
  max-width: 1680px;
}
.content-wrapper--vertical-spacing,
.dnd-section {
  padding: 40px 20px;
}
.dnd-section > .row-fluid {
  max-width: 1680px;
}
body .body-container-wrapper {
  padding: 20px;
}
.body-container .dnd-section {
  border: 1px solid #f3f3f7;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1680px;
  padding: 40px;
  position: relative;
  z-index: 10;
}
.body-container .dnd-section:not(:last-child) {
  margin-bottom: 40px;
}
.body-container .dnd-section:first-child {
  padding-top: 220px !important;
}
.body-container .dnd-section-system {
  background-image: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/alley-bg.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.body-container .dnd-section:first-child:before {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(66, 91, 118, 0.64)),
    to(rgba(66, 91, 118, 0.64))
  );
  background-image: linear-gradient(
    180deg,
    rgba(66, 91, 118, 0.64),
    rgba(66, 91, 118, 0.64)
  );
  border-radius: 20px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
@media (max-width: 1139px) {
  .body-container .dnd-section {
    padding: 20px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .body-container .dnd-section {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .body-container .dnd-section {
    padding: 20px;
  }
}
main p:last-child {
  margin-bottom: 0;
}

svg {
  fill: #30d5c8;
}
.footer h1,
.h1,
h1 {
  font-size: 60px;
}
.footer h1,
.footer h2,
.h1,
.h2,
h1,
h2 {
  color: #425b76;
  font-family: Exo, sans-serif;
  font-style: normal;
  font-weight: 800;
  text-decoration: none;
}
.footer h2,
.h2,
h2 {
  font-size: 40px;
}
.footer h3,
.h3,
h3 {
  font-size: 30px;
}
.footer h3,
.footer h4,
.h3,
.h4,
h3,
h4 {
  color: #425b76;
  font-family: Exo, sans-serif;
  font-style: normal;
  font-weight: 800;
  text-decoration: none;
}
.footer h4,
.h4,
h4 {
  font-size: 24px;
}
.footer h5,
.h5,
h5 {
  font-size: 20px;
}
.footer h5,
.footer h6,
.h5,
.h6,
h5,
h6 {
  color: #425b76;
  font-family: Exo, sans-serif;
  font-style: normal;
  font-weight: 800;
  text-decoration: none;
}
.footer h6,
.h6,
h6 {
  font-size: 18px;
}
@media (max-width: 1139px) {
  .footer h1,
  .h1,
  h1 {
    font-size: 52px;
  }
  .footer h2,
  .h2,
  h2 {
    font-size: 36px;
  }
  .footer h3,
  .h3,
  h3 {
    font-size: 24px;
  }
  .footer h4,
  .h4,
  h4 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .footer h1,
  .h1,
  h1 {
    font-size: 44px;
  }
  .footer h2,
  .h2,
  h2 {
    font-size: 30px;
  }
  .footer h3,
  .h3,
  h3 {
    font-size: 22px;
  }
}
.no-list {
  list-style: none;
}
.author-desc-line,
.card-title-line,
.heading-line {
  background-color: #30d5c8;
  border-radius: 2px;
  display: none;
  height: 4px;
  margin-bottom: 1.5em;
  transition: width 0.2s ease-in-out;
  width: 4em;
  z-index: 256;
}
.card-title:hover + .card-title-line,
.heading-text:hover + .heading-line {
  background-color: #30d5c8;
  width: 2em;
}
.author-desc-line,
.card-title-line,
.heading-line {
  display: block;
}
blockquote {
  border-left-color: #30d5c8;
}
.button,
.cta_button {
  fill: #fff;
  background-color: #30d5c8;
  border: 1px solid #30d5c8;
  border-radius: 20px;
  color: #fff;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 32px;
  transition: background-color 0.2s ease;
}
.button:focus,
.button:hover,
.cta_button:focus,
.cta_button:hover {
  background-color: #62fffa;
  border-color: #62fffa;
  color: #fff;
}
.button:active,
.cta_button:active {
  background-color: #80ffff;
  border-color: #80ffff;
  color: #fff;
}
.button-outline {
  background-color: transparent;
  border: 1px solid #30d5c8;
  border-radius: 20px;
  color: #30d5c8;
  font-family: Open Sans;
  font-weight: 600;
  padding: 16px 32px;
  transition: background-color 0.2s ease;
}
.button-outline.active,
.button-outline:active,
.button-outline:focus,
.button-outline:hover {
  background-color: #30d5c8;
  color: #fff;
}
.button .hs_cos_wrapper_type_icon,
.button-outline .hs_cos_wrapper_type_icon {
  display: inline-block;
  margin-left: 5px;
}
.button .hs_cos_wrapper_type_icon svg,
.button-outline .hs_cos_wrapper_type_icon svg {
  fill: inherit;
}
form {
  background-color: hsla(0, 0%, 100%, 0);
  border-radius: 20px;
  padding: 20px;
}
.submitted-message,
form {
  font-family:
    Open Sans,
    sans-serif;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}
.form-title {
  background-color: #30d5c8;
  color: #fff;
  margin: 0;
  padding: 20px;
}
.hs-error-msgs {
  list-style: none;
  padding: 0;
}
.hs-error-msg {
  color: #425b76;
}
.hs-form-required,
.hs_error_rollup {
  display: none;
}
fieldset {
  border: 0;
  margin: 0;
  max-width: 100% !important;
  min-width: 0;
  padding: 0;
}
fieldset.form-columns-1 .hs-input,
fieldset.form-columns-2 .hs-input,
fieldset.form-columns-3 .hs-input {
  width: 100% !important;
}
fieldset.form-columns-1 input[type="checkbox"],
fieldset.form-columns-1 input[type="radio"],
fieldset.form-columns-2 input[type="checkbox"],
fieldset.form-columns-2 input[type="radio"],
fieldset.form-columns-3 input[type="checkbox"],
fieldset.form-columns-3 input[type="radio"] {
  margin-right: 7px;
  margin-top: 4px;
  width: auto !important;
}
fieldset.form-columns-1 .input,
fieldset.form-columns-2 .input,
fieldset.form-columns-3 .input {
  margin-right: 0 !important;
}
.field,
.hs-richtext,
.hs-submit .actions,
fieldset.form-columns-1 .hs-form-field,
fieldset.form-columns-2 .hs-form-field,
fieldset.form-columns-3 .hs-form-field {
  padding-left: 10px;
  padding-right: 10px;
}
.hs-form-field + .hs-button {
  margin-left: 10px;
  margin-right: 10px;
}
.hs-membership-loader .actions {
  padding-left: 0;
  padding-right: 0;
}
fieldset.form-columns-3 .hs-form-field {
  width: 33.333333% !important;
}
@media (max-width: 767px) {
  fieldset.form-columns-2 .hs-form-field,
  fieldset.form-columns-3 .hs-form-field {
    width: 100% !important;
  }
}
form label,
form legend {
  color: #1c1d29;
}
form input[type="email"],
form input[type="file"],
form input[type="number"],
form input[type="password"],
form input[type="search"],
form input[type="tel"],
form input[type="text"],
form select,
form textarea {
  border-color: #f3f3f7;
  border-radius: 20px;
  color: #1c1d29;
}
form input[type="email"]:focus,
form input[type="file"]:focus,
form input[type="number"]:focus,
form input[type="password"]:focus,
form input[type="search"]:focus,
form input[type="tel"]:focus,
form input[type="text"]:focus,
form select:focus,
form textarea:focus {
  border-color: #425b76;
  outline: 0;
}
::-webkit-input-placeholder {
  color: #1c1d29;
}
.hs-fieldtype-date .input .hs-dateinput:before,
:-moz-placeholder,
:-ms-input-placeholder,
::-moz-placeholder,
::-webkit-input-placeholder,
::placeholder {
  color: #1c1d29;
}
.fn-date-picker td.is-selected .pika-button {
  background: #425b76;
}
.fn-date-picker td .pika-button:hover {
  background-color: #425b76 !important;
}
.fn-date-picker td.is-today .pika-button {
  color: #425b76;
}
form .hs-button,
form input[type="submit"] {
  background-color: #30d5c8;
  border: 1px solid #30d5c8;
  border-radius: 20px;
  color: #fff;
  font-family: Open Sans;
  font-weight: 600;
  padding: 16px 32px;
}
form .hs-button:focus,
form .hs-button:hover,
form input[type="submit"]:focus,
form input[type="submit"]:hover {
  background-color: #80ffff;
  border-color: #80ffff;
  color: #fff;
}
form .hs-button:active,
form input[type="submit"]:active {
  background-color: #12b7aa;
  border-color: #12b7aa;
  color: #fff;
}
table {
  background-color: #fff;
}
table,
td,
th {
  border-color: #425b76;
}
td,
th {
  color: #425b76;
}
thead td,
thead th {
  background-color: #425b76;
  border-bottom-color: #425b76;
  color: #fff;
}
tfoot td {
  background-color: #fff;
  color: #444;
}
.header {
  background-color: transparent;
}
.header__container .header__row-1 .header__row-1-container {
  margin: 0 auto;
  max-width: 1680px;
  padding: 0 20px;
}
[data-global-resource-path*="header-no-navigation.html"],
[data-global-resource-path*="header.html"] {
  left: 0;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 100%;
  z-index: 50;
}
[data-global-resource-path*="header-no-navigation.html"] .header,
[data-global-resource-path*="header.html"] .header {
  background-color: transparent;
  padding: 0;
}
[data-global-resource-path*="header-no-navigation.html"] .header.active,
[data-global-resource-path*="header.html"] .header.active {
  background-color: #fff;
  -webkit-box-shadow: 0 0 32px -16px rgba(0, 0, 0, 0.32);
  box-shadow: 0 0 32px -16px rgba(0, 0, 0, 0.32);
}
.header__container .header__row-1 {
  border: none;
}
.header__container .header__row-2 {
  border: 1px solid hsla(0, 0%, 100%, 0.16);
  max-width: 1600px;
  padding: 0 20px;
}
.header.active .header__container .header__row-2 {
  border: 1px solid #f3f3f7;
  margin-top: 0;
  max-width: 1680px;
}
.header__container .header__column {
  padding: 20px;
}
.header__language-switcher .lang_list_class li a,
.header__language-switcher-label-current,
.header__logo .logo-company-name,
body .navigation-primary a {
  color: #fff;
}
.header.active .header__language-switcher .lang_list_class li a,
.header.active .header__language-switcher-label-current,
.header.active .header__logo .logo-company-name,
body .header.active .navigation-primary a {
  color: #1c1d29;
}
body .navigation-primary .submenu.level-1 > .has-submenu > .menu-arrow {
  background: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/angle-down-light.svg);
}
body .submenu.level-1 > li:last-child {
  padding-right: 10px;
}
body
  .header.active
  .navigation-primary
  .submenu.level-1
  > .has-submenu
  > .menu-arrow {
  background: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/angle-down.svg);
}
body .submenu.level-3 {
  border-left: 3px solid #425b76;
}
.header__container-lp .header__row-2 .header-lp-col {
  color: #fff;
  flex: 1;
}
.header.active .header__container-lp .header__row-2 .header-lp-col {
  color: #1c1d29;
  flex: 1;
}
.info-text {
  border-color: #1c1d29;
}
.info-text,
.info-text a {
  color: #1c1d29;
}
.info-text .hs_cos_wrapper_type_icon svg {
  fill: #1c1d29;
}
.header__language-switcher .lang_list_class li a,
.header__language-switcher-label-current,
.header__logo .logo-company-name,
body .navigation-primary a {
  font-family:
    Open Sans,
    sans-serif;
  font-weight: 600;
}
.header__language-switcher .lang_list_class li a:active,
.header__language-switcher .lang_list_class li a:focus,
.header__language-switcher .lang_list_class li:hover a,
.header__language-switcher-label-current:active,
.header__language-switcher-label-current:focus,
.header__language-switcher-label-current:hover,
body .navigation-primary a:active,
body .navigation-primary a:focus,
body .navigation-primary a:hover {
  color: #211df9;
}
.navigation-primary .header-search svg {
  fill: #30d5c8;
}
.menu-button .hs-search-form .hs-search-submit svg {
  fill: #fff;
}
.header__language-switcher .lang_list_class li a {
  color: #425b76;
}
.header__language-switcher .lang_list_class li a:focus,
.header__language-switcher .lang_list_class li:hover a {
  color: #2e4762;
}
.header__language-switcher .lang_list_class li a:active {
  color: #243d58;
}
body .navigation-primary a.active-item {
  color: #30d5c8;
}
body .navigation-primary .submenu.level-1 > li > a.active-item:after {
  background-color: #30d5c8;
}
body .navigation-primary .submenu.level-1 > li > a.active-item:hover:after {
  transform: scaleX(1);
}
.header__language-switcher .lang_list_class:before,
body .submenu.level-2 > li:first-child:before {
  border-color: rgba(28, 29, 41, 0.5);
}
body .submenu.level-2 > li:not(:last-child),
body .submenu.level-3 > li:not(:last-child) {
  border-bottom: 1px solid rgba(28, 29, 41, 0.5);
}
.header__language-switcher .lang_list_class li,
body .submenu.level-2 .menu-item .menu-link,
body .submenu.level-2 > li:first-child:before {
  background-color: #211df9;
  color: #fff;
}
.header__language-switcher .lang_list_class li:hover,
body .submenu.level-2 .menu-item .menu-link:focus,
body .submenu.level-2 .menu-item .menu-link:hover,
body .submenu.level-2 > li:first-child.focus:before,
body .submenu.level-2 > li:first-child:hover:before {
  background-color: #fff;
  color: #211df9;
}
.header__language-switcher .lang_list_class.first-active:after {
  border-bottom-color: #fff;
}
.header__language-switcher .lang_list_class li a,
.header__language-switcher-label-current {
  font-family:
    Open Sans,
    sans-serif;
}
.header__language-switcher-label-current:after {
  border-top-color: #1c1d29;
}
body .navigation-primary .submenu.level-1 > .has-submenu > .menu-arrow {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  height: 12px;
  width: 12px;
}
.header__language-switcher--label-current {
  color: #1c1d29;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
}
[data-global-resource-path*="header.html"] .header .button-outline-light,
[data-global-resource-path*="header.html"] .header .cta_button {
  background-color: transparent;
  border: 2px solid #1c1d29;
  color: #1c1d29;
  cursor: pointer;
  display: inline-block;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
[data-global-resource-path*="header.html"] .header .button-outline-light:focus,
[data-global-resource-path*="header.html"] .header .button-outline-light:hover,
[data-global-resource-path*="header.html"] .header .cta_button:focus,
[data-global-resource-path*="header.html"] .header .cta_button:hover {
  border-color: #080915;
}
.header__navigation {
  align-items: center;
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.header__search {
  display: none;
}
.header__row-1 {
  border-bottom: 1px solid transparent;
  padding: 0;
}
.info-text .hs_cos_wrapper_type_icon svg {
  margin-right: 5px;
}
.info-text-2.info-no-language {
  border: 0;
  margin-right: 0;
  padding-right: 0;
}
.header__container-lp .header__row-2 {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding: 20px;
}
@media (max-width: 1280px) and (min-width: 1139px) {
  .header .header__container .header__column {
    width: auto;
  }
  .header.active .header__container .header__column {
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px 0;
    width: auto;
  }
}
@media (max-width: 1139px) {
  .header__navigation {
    display: none;
  }
  .header__navigation--toggle {
    margin-right: 0;
  }
  .info-text {
    border-color: #1c1d29;
  }
  .info-text,
  .info-text a {
    color: #1c1d29;
  }
  .info-text .hs_cos_wrapper_type_icon svg {
    fill: #1c1d29;
  }
  .info-text-1 + .info-text-2,
  .info-text-1.info-solo {
    border: 0;
    margin-right: 0;
    padding-right: 0;
  }
  .header__container {
    display: block;
  }
  .header__container .header__row-2,
  .header__row-1 {
    padding: 0;
  }
  .header__container .header__row-2 {
    padding: 20px;
  }
  body
    .header.active
    .navigation-primary
    .submenu.level-1
    > .has-submenu
    > .menu-arrow {
    background: none;
  }
  body .header.active .navigation-primary a,
  body .header.active .submenu.level-1 > li > a {
    color: #fff;
  }
  body .header.active .navigation-primary a:hover,
  body .header.active .submenu.level-1 > li > a:hover {
    color: #30d5c8;
  }
  [data-global-resource-path*="header-no-navigation.html"] .header,
  [data-global-resource-path*="header.html"] .header {
    background-color: transparent;
  }
  [data-global-resource-path*="header-no-navigation.html"] .header.active,
  [data-global-resource-path*="header.html"] .header.active {
    background-color: #fff;
  }
  .header__navigation--toggle {
    background-image: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/bars-light.svg);
  }
  .header__close--toggle,
  .header__navigation--toggle {
    background-color: #30d5c8;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    border-radius: 10px;
    height: 50px;
    padding: 0;
    width: 50px;
  }
  .header__close--toggle {
    background-image: url(//39844124.fs1.hubspotusercontent-na1.net/hubfs/39844124/raw_assets/public/@marketplace/Juice_Tactics_Snacks/Thrifty/images/times-light.svg);
  }
  .header__language-switcher.open,
  .header__navigation.open,
  .header__search.open {
    background-color: transparent;
    padding: 0 20px 20px;
  }
  .navigation-primary {
    display: block;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
    padding: 0;
  }
  body .navigation-primary .submenu.level-1 {
    background-color: #425b76;
    border: 1px solid #30d5c8;
    border-radius: 20px;
    width: 100%;
  }
  body .navigation-primary .submenu.level-1 > li {
    border-top: 0;
  }
  body .navigation-primary .submenu.level-2 {
    padding: 0 20px;
  }
  body .submenu.level-3 {
    border-left: 3px solid #30d5c8;
  }
  body .submenu.level-2 .menu-item,
  body .submenu.level-3 .menu-item {
    background-color: transparent;
    border-bottom: none;
    padding: 0;
  }
  body .submenu.level-2 .menu-item.has-submenu,
  body .submenu.level-3 .menu-item.has-submenu {
    background-color: #fff;
  }
  body .submenu.level-2 .menu-item .menu-link {
    background-color: #fff;
    color: #425b76;
    padding: 15px 20px;
    text-indent: 0;
    transition: none;
    transition: background-color 0.3s;
  }
  body .submenu.level-3 .menu-item .menu-link {
    background-color: #fff;
    color: #425b76;
    padding: 15px 20px;
    text-indent: 20px;
    transition: none;
  }
  body .submenu.level-2 .menu-item .menu-link:hover,
  body .submenu.level-3 .menu-item .menu-link:hover {
    color: #30d5c8;
  }
  .header.active .header__container .header__column {
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px 0;
  }
  body .navigation-primary .submenu.level-1 > .has-submenu > .menu-arrow {
    background: none;
    height: auto;
    width: auto;
  }
  .header__language-switcher .lang_list_class {
    background-color: #fff;
  }
  .header__language-switcher .lang_list_class a:focus,
  .header__language-switcher .lang_list_class a:hover {
    background-color: none;
  }
  .header__navigation {
    background-color: #fff;
  }
  .header__navigation-toggle svg,
  .menu-arrow svg {
    fill: #1c1d29;
  }
  .menu-button .button-outline-light,
  .menu-button .cta_button {
    background-color: #425b76 !important;
    border-color: #425b76 !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    padding: 15px 20px !important;
  }
  body .navigation-primary .button-outline-light:focus,
  body .navigation-primary .button-outline-light:hover,
  body .navigation-primary .cta_button:focus,
  body .navigation-primary .cta_button:hover {
    background-color: #2e4762;
    border-color: #2e4762;
    color: #fff;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .body-container .dnd-section:first-child {
    padding-top: 200px !important;
  }
  .header__container .header__column {
    padding: 10px;
    padding: 20px;
  }
}
@media (max-width: 767px) {
  [data-global-resource-path*="header-no-navigation.html"] .header,
  [data-global-resource-path*="header.html"] .header {
    background-color: transparent;
  }
  .body-container .dnd-section:first-child {
    padding-top: 150px !important;
  }
  .header__container .header__column {
    padding: 20px;
  }
}
@media (max-width: 479px) {
  .header__logo {
    max-width: 150px;
  }
  .header__close--toggle,
  .header__navigation--toggle {
    background-color: #30d5c8;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 15px;
    border-radius: 10px;
    height: 30px;
    padding: 0;
    width: 30px;
  }
}
.footer {
  background-color: hsla(0, 0%, 100%, 0);
  font-family:
    Open Sans,
    sans-serif;
}
.footer .social-links {
  justify-content: start;
}
.footer .social-links .social-links__link:not(:last-child) {
  margin-right: 10px;
}
.footer .social-links__icon {
  margin: 0;
}
.footer a {
  color: #1c1d29;
  text-decoration: none;
}
.footer a:focus,
.footer a:hover {
  color: #30d5c8;
}
.footer .hs-menu-flow-horizontal ul {
  list-style: none;
  padding-left: 0;
}
.footer .hs-menu-flow-horizontal ul .hs-menu-item {
  display: inline-block;
}
.footer .hs-menu-flow-horizontal ul .hs-menu-item:not(:last-child) {
  margin-right: 15px;
}
.footer .hs-menu-flow-vertical ul .hs-menu-item a {
  display: inline-block;
  font-size: 14px;
  padding: 5px 0;
}
.footer li,
.footer p,
.footer span {
  font-size: 14px;
}
.footer img,
.footer label,
.footer li,
.footer p,
.footer span {
  color: #1c1d29;
}
.footer form input[type="email"],
.footer form input[type="file"],
.footer form input[type="number"],
.footer form input[type="password"],
.footer form input[type="search"],
.footer form input[type="tel"],
.footer form input[type="text"],
.footer form select,
.footer form textarea {
  padding: 8px 12px;
}
.footer form .hs-button,
.footer form input[type="submit"] {
  display: block;
  font-size: 16px;
  padding: 8px 12px;
  width: 100%;
}
.footer-social-follow .social-links {
  justify-content: flex-end;
}
.footer-powered-text {
  text-align: right;
}
@media (max-width: 767px) {
  .footer-social-follow .social-links {
    justify-content: flex-start;
  }
  .footer .widget-type-header h1,
  .footer .widget-type-header h2,
  .footer .widget-type-header h3,
  .footer .widget-type-header h4,
  .footer .widget-type-header h5,
  .footer .widget-type-header h6 {
    margin-bottom: 10px;
  }
}
.blog-header__inner,
.blog-post,
.blog-related-posts {
  padding: 40px 0;
}
.blog-index__post-title-link {
  color: #425b76;
}
.blog-index__post-author-name,
.blog-index__post-tag {
  color: #1c1d29;
}
.blog-pagination__link {
  border: 1.5px solid #30d5c8;
  border-radius: 20px;
  color: #30d5c8;
}
.blog-pagination__link--active,
.blog-pagination__link:focus,
.blog-pagination__link:hover {
  background-color: #30d5c8;
  border: 1.5px solid #30d5c8;
  color: #fff;
}
.blog-pagination__link--active:after,
.blog-pagination__next-link:after,
.blog-pagination__prev-link:after {
  background-color: #30d5c8;
}
.blog-pagination__next-link svg,
.blog-pagination__prev-link svg {
  fill: #30d5c8;
}
.blog-pagination__next-link:hover svg,
.blog-pagination__prev-link:hover svg {
  fill: #fff;
}
.blog-post__meta a,
.blog-post__tag-link {
  color: #1c1d29;
}
.blog-post__tag-link:focus,
.blog-post__tag-link:hover {
  color: #080915;
}
.blog-post__tag-link:active {
  color: #00000b;
}
.blog-related-posts__title a {
  color: #425b76;
}
.blog-comments {
  margin-bottom: 40px;
}
#comments-listing .comment-reply-to {
  color: #30d5c8;
}
#comments-listing .comment-reply-to:focus,
#comments-listing .comment-reply-to:hover {
  color: #1cc1b4;
}
#comments-listing .comment-reply-to:active {
  color: #12b7aa;
}
.hs-search-results__title {
  color: #30d5c8;
}
.hs-search-results__pagination a:empty {
  display: none;
}
.card__price {
  color: #425b76;
  font-family: Exo, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  text-decoration: none;
}
.tagline-wrap {
  align-items: center;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  font-family: Exo, sans-serif;
  letter-spacing: 1px;
}
.tagline-wrap .tagline-line {
  border-radius: 2px;
  height: 4px;
  width: 2em;
}
.text-center .tagline-wrap {
  justify-content: center;
}
.text-left .tagline-wrap {
  justify-content: flex-start;
}
.text-right .tagline-wrap {
  justify-content: flex-end;
}
.text-center .author-desc-line,
.text-center .card-title-line,
.text-center .heading-line {
  margin-left: auto;
  margin-right: auto;
}
.text-left .card-title-line,
.text-left .heading-line {
  margin-left: 0;
  margin-right: auto;
}
.text-right .card-title-line,
.text-right .heading-line {
  margin-left: auto;
  margin-right: 0;
}
.button-full-width .cta_button,
.button-full-width a {
  width: 100%;
}
.box-shadow {
  -webkit-box-shadow: 0 0 32px -16px rgba(0, 0, 0, 0.32);
  box-shadow: 0 0 32px -16px rgba(0, 0, 0, 0.32);
}
.border-radius {
  border-radius: 20px;
}
.border-radius_full {
  border-radius: 100%;
}
.card-border {
  border: 1px solid #f3f3f7;
}
.border-radius > img {
  border-radius: 20px;
}
.primary-color {
  color: #425b76;
}
.primary-bg-color {
  background-color: #425b76;
}
.primary-border-color {
  border-color: #425b76;
}
.secondary-color {
  color: #30d5c8;
}
.secondary-accent-color {
  background-color: #adffff;
}
.secondary-bg-color {
  background-color: #30d5c8;
}
.secondary-border-color {
  border-color: #30d5c8;
}
.light-bg-color {
  background-color: #fff;
}
.light-color {
  color: #fff;
}
.default-bg-color {
  background-color: #f3f3f7;
}
.tagline {
  color: #30d5c8;
}
.primary-font {
  font-family: Exo, sans-serif;
}
.secondary-font {
  font-family:
    Open Sans,
    sans-serif;
}
.card-icon-wrapper {
  height: 120px;
  width: 120px;
}
.card-icon-wrapper,
.card-share-icon-wrapper {
  align-items: center;
  background-color: #f3f3f7;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}
.card-share-icon-wrapper {
  border-radius: 100%;
  height: 32px;
  width: 32px;
}
.text-center .card-icon-wrapper {
  margin-left: auto;
  margin-right: auto;
}
.text-left .card-icon-wrapper {
  margin-left: 0;
  margin-right: auto;
}
.text-right .card-icon-wrapper {
  margin-left: auto;
  margin-right: 0;
}
.letter-spacing-1 {
  letter-spacing: 1px;
}
.letter-spacing-2 {
  letter-spacing: 2px;
}
.list-inline {
  list-style: none;
  padding-left: 0;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 8px;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
@media (max-width: 1139px) {
  .text-tablet-left {
    text-align: left;
  }
  .text-tablet-right {
    text-align: right;
  }
  .text-tablet-center {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .text-phone-left {
    text-align: left;
  }
  .text-phone-right {
    text-align: right;
  }
  .text-phone-center {
    text-align: center;
  }
}
.line-height-1 {
  line-height: 1;
}
.line-height-11 {
  line-height: 1.1;
}
.line-height-12 {
  line-height: 1.2;
}
.line-height-13 {
  line-height: 1.3;
}
.line-height-14 {
  line-height: 1.4;
}
.line-height-15 {
  line-height: 1.5;
}
.line-height-16 {
  line-height: 1.6;
}
.line-height-17 {
  line-height: 1.7;
}
.line-height-18 {
  line-height: 1.8;
}
.line-height-19 {
  line-height: 1.9;
}
.line-height-2 {
  line-height: 2;
}
@media (max-width: 1139px) {
  .line-height-tablet-1 {
    line-height: 1;
  }
  .line-height-tablet-11 {
    line-height: 1.1;
  }
  .line-height-tablet-12 {
    line-height: 1.2;
  }
  .line-height-tablet-13 {
    line-height: 1.3;
  }
  .line-height-tablet-14 {
    line-height: 1.4;
  }
  .line-height-tablet-15 {
    line-height: 1.5;
  }
}
@media (max-width: 767px) {
  .line-height-phone-1 {
    line-height: 1;
  }
  .line-height-phone-11 {
    line-height: 1.1;
  }
  .line-height-phone-12 {
    line-height: 1.2;
  }
  .line-height-phone-13 {
    line-height: 1.3;
  }
  .line-height-phone-14 {
    line-height: 1.4;
  }
  .line-height-phone-15 {
    line-height: 1.5;
  }
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.float-none {
  float: none;
}
@media (max-width: 1139px) {
  .float-tablet-left {
    float: left;
  }
  .float-tablet-right {
    float: right;
  }
  .float-tablet-none {
    float: none;
  }
}
@media (max-width: 767px) {
  .float-phone-left {
    float: left;
  }
  .float-phone-right {
    float: right;
  }
  .float-phone-none {
    float: none;
  }
}
.font-weight-100 {
  font-weight: 100;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.font-weight-900 {
  font-weight: 900;
}
.container-fluid .row-fluid .max-width-1200,
.max-width-1200 {
  max-width: 1200px;
}
.container-fluid .row-fluid .max-width-1190,
.max-width-1190 {
  max-width: 1190px;
}
.container-fluid .row-fluid .max-width-1180,
.max-width-1180 {
  max-width: 1180px;
}
.container-fluid .row-fluid .max-width-1170,
.max-width-1170 {
  max-width: 1170px;
}
.container-fluid .row-fluid .max-width-1160,
.max-width-1160 {
  max-width: 1160px;
}
.container-fluid .row-fluid .max-width-1150,
.max-width-1150 {
  max-width: 1150px;
}
.container-fluid .row-fluid .max-width-1140,
.max-width-1140 {
  max-width: 1140px;
}
.container-fluid .row-fluid .max-width-1130,
.max-width-1130 {
  max-width: 1130px;
}
.container-fluid .row-fluid .max-width-1120,
.max-width-1120 {
  max-width: 1120px;
}
.container-fluid .row-fluid .max-width-1110,
.max-width-1110 {
  max-width: 1110px;
}
.container-fluid .row-fluid .max-width-1100,
.max-width-1100 {
  max-width: 1100px;
}
.container-fluid .row-fluid .max-width-1090,
.max-width-1090 {
  max-width: 1090px;
}
.container-fluid .row-fluid .max-width-1080,
.max-width-1080 {
  max-width: 1080px;
}
.container-fluid .row-fluid .max-width-1070,
.max-width-1070 {
  max-width: 1070px;
}
.container-fluid .row-fluid .max-width-1060,
.max-width-1060 {
  max-width: 1060px;
}
.container-fluid .row-fluid .max-width-1050,
.max-width-1050 {
  max-width: 1050px;
}
.container-fluid .row-fluid .max-width-1000,
.max-width-1000 {
  max-width: 1000px;
}
.container-fluid .row-fluid .max-width-950,
.max-width-950 {
  max-width: 950px;
}
.container-fluid .row-fluid .max-width-900,
.max-width-900 {
  max-width: 900px;
}
.container-fluid .row-fluid .max-width-850,
.max-width-850 {
  max-width: 850px;
}
.container-fluid .row-fluid .max-width-800,
.max-width-800 {
  max-width: 800px;
}
.container-fluid .row-fluid .max-width-750,
.max-width-750 {
  max-width: 750px;
}
.container-fluid .row-fluid .max-width-700,
.max-width-700 {
  max-width: 700px;
}
.container-fluid .row-fluid .max-width-650,
.max-width-650 {
  max-width: 650px;
}
.container-fluid .row-fluid .max-width-600,
.max-width-600 {
  max-width: 600px;
}
.container-fluid .row-fluid .max-width-550,
.max-width-550 {
  max-width: 550px;
}
.container-fluid .row-fluid .max-width-500,
.max-width-500 {
  max-width: 500px;
}
.container-fluid .row-fluid .max-width-450,
.max-width-450 {
  max-width: 450px;
}
.container-fluid .row-fluid .max-width-400,
.max-width-400 {
  max-width: 400px;
}
.container-fluid .row-fluid .max-width-350,
.max-width-350 {
  max-width: 350px;
}
.container-fluid .row-fluid .max-width-300,
.max-width-300 {
  max-width: 300px;
}
.min-h-0 {
  min-height: 0;
}
.min-h-full {
  min-height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.m-auto {
  margin: auto;
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.m-0 {
  margin: 0;
}
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.m-25 {
  margin: 25px;
}
.m-30 {
  margin: 30px;
}
.m-35 {
  margin: 35px;
}
.m-40 {
  margin: 40px;
}
.m-45 {
  margin: 45px;
}
.m-50 {
  margin: 50px;
}
.m-55 {
  margin: 55px;
}
.m-60 {
  margin: 60px;
}
.m-65 {
  margin: 65px;
}
.m-70 {
  margin: 70px;
}
.m-75 {
  margin: 75px;
}
.m-80 {
  margin: 80px;
}
.mt-0 {
  margin-top: 0;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-80 {
  margin-top: 80px;
}
.mr-0 {
  margin-right: 0;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-55 {
  margin-right: 55px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-65 {
  margin-right: 65px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-75 {
  margin-right: 75px;
}
.mr-80 {
  margin-right: 80px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-80 {
  margin-bottom: 80px;
}
.ml-0 {
  margin-left: 0;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-55 {
  margin-left: 55px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-65 {
  margin-left: 65px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-75 {
  margin-left: 75px;
}
.ml-80 {
  margin-left: 80px;
}
@media (max-width: 1139px) {
  .m-30,
  .m-35 {
    margin: 25px;
  }
  .m-40,
  .m-45 {
    margin: 30px;
  }
  .m-50,
  .m-55 {
    margin: 35px;
  }
  .m-60,
  .m-65 {
    margin: 40px;
  }
  .m-70,
  .m-75,
  .m-80 {
    margin: 45px;
  }
  .mt-30,
  .mt-35 {
    margin-top: 25px;
  }
  .mt-40,
  .mt-45 {
    margin-top: 30px;
  }
  .mt-50,
  .mt-55 {
    margin-top: 35px;
  }
  .mt-60,
  .mt-65 {
    margin-top: 40px;
  }
  .mt-70,
  .mt-75,
  .mt-80 {
    margin-top: 45px;
  }
  .mr-30,
  .mr-35 {
    margin-right: 25px;
  }
  .mr-40,
  .mr-45 {
    margin-right: 30px;
  }
  .mr-50,
  .mr-55 {
    margin-right: 35px;
  }
  .mr-60,
  .mr-65 {
    margin-right: 40px;
  }
  .mr-70,
  .mr-75,
  .mr-80 {
    margin-right: 45px;
  }
  .mb-30,
  .mb-35 {
    margin-bottom: 25px;
  }
  .mb-40,
  .mb-45 {
    margin-bottom: 30px;
  }
  .mb-50,
  .mb-55 {
    margin-bottom: 35px;
  }
  .mb-60,
  .mb-65 {
    margin-bottom: 40px;
  }
  .mb-70,
  .mb-75,
  .mb-80 {
    margin-bottom: 45px;
  }
  .ml-30,
  .ml-35 {
    margin-left: 25px;
  }
  .ml-40,
  .ml-45 {
    margin-left: 30px;
  }
  .ml-50,
  .ml-55 {
    margin-left: 35px;
  }
  .ml-60,
  .ml-65 {
    margin-left: 40px;
  }
  .ml-70,
  .ml-75,
  .ml-80 {
    margin-left: 45px;
  }
  .m-tablet-0 {
    margin: 0;
  }
  .mt-tablet-0 {
    margin-top: 0;
  }
  .mr-tablet-0 {
    margin-right: 0;
  }
  .mb-tablet-0 {
    margin-bottom: 0;
  }
  .ml-tablet-0 {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .m-15,
  .m-20,
  .m-25 {
    margin: 10px;
  }
  .m-30,
  .m-35 {
    margin: 15px;
  }
  .m-40,
  .m-45 {
    margin: 20px;
  }
  .m-50,
  .m-55 {
    margin: 25px;
  }
  .m-60,
  .m-65 {
    margin: 30px;
  }
  .m-70,
  .m-75,
  .m-80 {
    margin: 35px;
  }
  .mt-15,
  .mt-20,
  .mt-25 {
    margin-top: 10px;
  }
  .mt-30,
  .mt-35 {
    margin-top: 15px;
  }
  .mt-40,
  .mt-45 {
    margin-top: 20px;
  }
  .mt-50,
  .mt-55 {
    margin-top: 25px;
  }
  .mt-60,
  .mt-65 {
    margin-top: 30px;
  }
  .mt-70,
  .mt-75,
  .mt-80 {
    margin-top: 35px;
  }
  .mr-15,
  .mr-20,
  .mr-25 {
    margin-right: 10px;
  }
  .mr-30,
  .mr-35 {
    margin-right: 15px;
  }
  .mr-40,
  .mr-45 {
    margin-right: 20px;
  }
  .mr-50,
  .mr-55 {
    margin-right: 25px;
  }
  .mr-60,
  .mr-65 {
    margin-right: 30px;
  }
  .mr-70,
  .mr-75,
  .mr-80 {
    margin-right: 35px;
  }
  .mb-15,
  .mb-20,
  .mb-25 {
    margin-bottom: 10px;
  }
  .mb-30,
  .mb-35 {
    margin-bottom: 15px;
  }
  .mb-40,
  .mb-45 {
    margin-bottom: 20px;
  }
  .mb-50,
  .mb-55 {
    margin-bottom: 25px;
  }
  .mb-60,
  .mb-65 {
    margin-bottom: 30px;
  }
  .mb-70,
  .mb-75,
  .mb-80 {
    margin-bottom: 35px;
  }
  .ml-15,
  .ml-20,
  .ml-25 {
    margin-left: 10px;
  }
  .ml-30,
  .ml-35 {
    margin-left: 15px;
  }
  .ml-40,
  .ml-45 {
    margin-left: 20px;
  }
  .ml-50,
  .ml-55 {
    margin-left: 25px;
  }
  .ml-60,
  .ml-65 {
    margin-left: 30px;
  }
  .ml-70,
  .ml-75,
  .ml-80 {
    margin-left: 35px;
  }
  .m-phone-0 {
    margin: 0;
  }
  .mt-phone-0 {
    margin-top: 0;
  }
  .mr-phone-0 {
    margin-right: 0;
  }
  .mb-phone-0 {
    margin-bottom: 0;
  }
  .ml-phone-0 {
    margin-left: 0;
  }
}
.p-0 {
  padding: 0;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}
.p-30 {
  padding: 30px;
}
.p-35 {
  padding: 35px;
}
.p-40 {
  padding: 40px;
}
.p-45 {
  padding: 45px;
}
.p-50 {
  padding: 50px;
}
.p-55 {
  padding: 55px;
}
.p-60 {
  padding: 60px;
}
.p-65 {
  padding: 65px;
}
.p-70 {
  padding: 70px;
}
.p-75 {
  padding: 75px;
}
.p-80 {
  padding: 80px;
}
.p-85 {
  padding: 85px;
}
.p-90 {
  padding: 90px;
}
.p-95 {
  padding: 95px;
}
.p-100 {
  padding: 100px;
}
.p-120 {
  padding: 120px;
}
.p-150 {
  padding: 150px;
}
.py-0 {
  padding-bottom: 0;
  padding-top: 0;
}
.py-5 {
  padding-bottom: 5px;
  padding-top: 5px;
}
.py-10 {
  padding-bottom: 10px;
  padding-top: 10px;
}
.py-15 {
  padding-bottom: 15px;
  padding-top: 15px;
}
.py-20 {
  padding-bottom: 20px;
  padding-top: 20px;
}
.py-25 {
  padding-bottom: 25px;
  padding-top: 25px;
}
.py-30 {
  padding-bottom: 30px;
  padding-top: 30px;
}
.py-35 {
  padding-bottom: 35px;
  padding-top: 35px;
}
.py-40 {
  padding-bottom: 40px;
  padding-top: 40px;
}
.py-45 {
  padding-bottom: 45px;
  padding-top: 45px;
}
.py-50 {
  padding-bottom: 50px;
  padding-top: 50px;
}
.py-55 {
  padding-bottom: 55px;
  padding-top: 55px;
}
.py-60 {
  padding-bottom: 60px;
  padding-top: 60px;
}
.py-65 {
  padding-bottom: 65px;
  padding-top: 65px;
}
.py-70 {
  padding-bottom: 70px;
  padding-top: 70px;
}
.py-75 {
  padding-bottom: 75px;
  padding-top: 75px;
}
.py-80 {
  padding-bottom: 80px;
  padding-top: 80px;
}
.py-85 {
  padding-bottom: 85px;
  padding-top: 85px;
}
.py-90 {
  padding-bottom: 90px;
  padding-top: 90px;
}
.py-95 {
  padding-bottom: 95px;
  padding-top: 95px;
}
.py-100 {
  padding-bottom: 100px;
  padding-top: 100px;
}
.py-120 {
  padding-bottom: 120px;
  padding-top: 120px;
}
.py-150 {
  padding-bottom: 150px;
  padding-top: 150px;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}
.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}
.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.px-55 {
  padding-left: 55px;
  padding-right: 55px;
}
.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}
.px-65 {
  padding-left: 65px;
  padding-right: 65px;
}
.px-70 {
  padding-left: 70px;
  padding-right: 70px;
}
.px-75 {
  padding-left: 75px;
  padding-right: 75px;
}
.px-80 {
  padding-left: 80px;
  padding-right: 80px;
}
.px-85 {
  padding-left: 85px;
  padding-right: 85px;
}
.px-90 {
  padding-left: 90px;
  padding-right: 90px;
}
.px-95 {
  padding-left: 95px;
  padding-right: 95px;
}
.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}
.px-120 {
  padding-left: 120px;
  padding-right: 120px;
}
.px-150 {
  padding-left: 150px;
  padding-right: 150px;
}
.pt-0 {
  padding-top: 0;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-55 {
  padding-top: 55px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-85 {
  padding-top: 85px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-95 {
  padding-top: 95px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-120 {
  padding-top: 120px;
}
.pt-150 {
  padding-top: 150px;
}
.pr-0 {
  padding-right: 0;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-55 {
  padding-right: 55px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-65 {
  padding-right: 65px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-75 {
  padding-right: 75px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-85 {
  padding-right: 85px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-95 {
  padding-right: 95px;
}
.pr-100 {
  padding-right: 100px;
}
.pr-120 {
  padding-right: 120px;
}
.pr-150 {
  padding-right: 150px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-85 {
  padding-bottom: 85px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-95 {
  padding-bottom: 95px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pl-0 {
  padding-left: 0;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-55 {
  padding-left: 55px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-65 {
  padding-left: 65px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-75 {
  padding-left: 75px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-85 {
  padding-left: 85px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-100 {
  padding-left: 100px;
}
.pl-120 {
  padding-left: 120px;
}
.pl-150 {
  padding-left: 150px;
}
@media (max-width: 1139px) {
  .p-30,
  .p-35 {
    padding: 25px;
  }
  .p-40,
  .p-45 {
    padding: 30px;
  }
  .p-50,
  .p-55 {
    padding: 40px;
  }
  .p-60,
  .p-65,
  .p-70,
  .p-75,
  .p-80,
  .p-85,
  .p-90 {
    padding: 50px;
  }
  .p-100,
  .p-120,
  .p-150,
  .p-95 {
    padding: 60px;
  }
  .py-30,
  .py-35 {
    padding-bottom: 25px;
    padding-top: 25px;
  }
  .py-40,
  .py-45 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .py-50,
  .py-55 {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .py-60,
  .py-65,
  .py-70,
  .py-75,
  .py-80,
  .py-85,
  .py-90 {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .py-100,
  .py-120,
  .py-150,
  .py-95 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .px-30,
  .px-35 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .px-40,
  .px-45 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .px-50,
  .px-55 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .px-60,
  .px-65,
  .px-70,
  .px-75,
  .px-80,
  .px-85,
  .px-90 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .px-100,
  .px-120,
  .px-150,
  .px-95 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pt-30,
  .pt-35 {
    padding-top: 25px;
  }
  .pt-40,
  .pt-45 {
    padding-top: 30px;
  }
  .pt-50,
  .pt-55 {
    padding-top: 40px;
  }
  .pt-60,
  .pt-65,
  .pt-70,
  .pt-75,
  .pt-80,
  .pt-85,
  .pt-90 {
    padding-top: 50px;
  }
  .pt-100,
  .pt-120,
  .pt-150,
  .pt-95 {
    padding-top: 60px;
  }
  .pr-30,
  .pr-35 {
    padding-right: 25px;
  }
  .pr-40,
  .pr-45 {
    padding-right: 30px;
  }
  .pr-50,
  .pr-55 {
    padding-right: 40px;
  }
  .pr-60,
  .pr-65,
  .pr-70,
  .pr-75,
  .pr-80,
  .pr-85,
  .pr-90 {
    padding-right: 50px;
  }
  .pr-100,
  .pr-120,
  .pr-150,
  .pr-95 {
    padding-right: 60px;
  }
  .pb-30,
  .pb-35 {
    padding-bottom: 25px;
  }
  .pb-40,
  .pb-45 {
    padding-bottom: 30px;
  }
  .pb-50,
  .pb-55 {
    padding-bottom: 40px;
  }
  .pb-60,
  .pb-65,
  .pb-70,
  .pb-75,
  .pb-80,
  .pb-85,
  .pb-90 {
    padding-bottom: 50px;
  }
  .pb-100,
  .pb-120,
  .pb-150,
  .pb-95 {
    padding-bottom: 60px;
  }
  .pl-30,
  .pl-35 {
    padding-left: 25px;
  }
  .pl-40,
  .pl-45 {
    padding-left: 30px;
  }
  .pl-50,
  .pl-55 {
    padding-left: 40px;
  }
  .pl-60,
  .pl-65,
  .pl-70,
  .pl-75,
  .pl-80,
  .pl-85,
  .pl-90 {
    padding-left: 50px;
  }
  .pl-100,
  .pl-120,
  .pl-150,
  .pl-95 {
    padding-left: 60px;
  }
  .p-tablet-0 {
    padding: 0;
  }
  .pt-tablet-0 {
    padding-top: 0;
  }
  .pr-tablet-0 {
    padding-right: 0;
  }
  .pb-tablet-0 {
    padding-bottom: 0;
  }
  .pl-tablet-0 {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .p-15,
  .p-20,
  .p-25,
  .p-5 {
    padding: 10px;
  }
  .p-30,
  .p-35 {
    padding: 15px;
  }
  .p-40,
  .p-45 {
    padding: 20px;
  }
  .p-50,
  .p-55 {
    padding: 25px;
  }
  .p-100,
  .p-120,
  .p-150,
  .p-60,
  .p-65,
  .p-70,
  .p-75,
  .p-80,
  .p-85,
  .p-90,
  .p-95 {
    padding: 30px;
  }
  .py-15,
  .py-20,
  .py-25,
  .py-5 {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .py-30,
  .py-35 {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .py-40,
  .py-45 {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .py-50,
  .py-55 {
    padding-bottom: 25px;
    padding-top: 25px;
  }
  .py-100,
  .py-120,
  .py-150,
  .py-60,
  .py-65,
  .py-70,
  .py-75,
  .py-80,
  .py-85,
  .py-90,
  .py-95 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .px-15,
  .px-20,
  .px-25,
  .px-5 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .px-30,
  .px-35 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .px-40,
  .px-45 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .px-50,
  .px-55 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .px-100,
  .px-120,
  .px-150,
  .px-60,
  .px-65,
  .px-70,
  .px-75,
  .px-80,
  .px-85,
  .px-90,
  .px-95 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pt-15,
  .pt-20,
  .pt-25,
  .pt-5 {
    padding-top: 10px;
  }
  .pt-30,
  .pt-35 {
    padding-top: 15px;
  }
  .pt-40,
  .pt-45 {
    padding-top: 20px;
  }
  .pt-50,
  .pt-55 {
    padding-top: 25px;
  }
  .pt-100,
  .pt-120,
  .pt-150,
  .pt-60,
  .pt-65,
  .pt-70,
  .pt-75,
  .pt-80,
  .pt-85,
  .pt-90,
  .pt-95 {
    padding-top: 30px;
  }
  .pr-15,
  .pr-20,
  .pr-25,
  .pr-5 {
    padding-right: 10px;
  }
  .pr-30,
  .pr-35 {
    padding-right: 15px;
  }
  .pr-40,
  .pr-45 {
    padding-right: 20px;
  }
  .pr-50,
  .pr-55 {
    padding-right: 25px;
  }
  .pr-100,
  .pr-120,
  .pr-150,
  .pr-60,
  .pr-65,
  .pr-70,
  .pr-75,
  .pr-80,
  .pr-85,
  .pr-90,
  .pr-95 {
    padding-right: 30px;
  }
  .pb-15,
  .pb-20,
  .pb-25,
  .pb-5 {
    padding-bottom: 10px;
  }
  .pb-30,
  .pb-35 {
    padding-bottom: 15px;
  }
  .pb-40,
  .pb-45 {
    padding-bottom: 20px;
  }
  .pb-50,
  .pb-55 {
    padding-bottom: 25px;
  }
  .pb-100,
  .pb-120,
  .pb-150,
  .pb-60,
  .pb-65,
  .pb-70,
  .pb-75,
  .pb-80,
  .pb-85,
  .pb-90,
  .pb-95 {
    padding-bottom: 30px;
  }
  .pl-15,
  .pl-20,
  .pl-25,
  .pl-5 {
    padding-left: 10px;
  }
  .pl-30,
  .pl-35 {
    padding-left: 15px;
  }
  .pl-40,
  .pl-45 {
    padding-left: 20px;
  }
  .pl-50,
  .pl-55 {
    padding-left: 25px;
  }
  .pl-100,
  .pl-120,
  .pl-150,
  .pl-60,
  .pl-65,
  .pl-70,
  .pl-75,
  .pl-80,
  .pl-85,
  .pl-90,
  .pl-95 {
    padding-left: 30px;
  }
  .p-phone-0 {
    padding: 0;
  }
  .pt-phone-0 {
    padding-top: 0;
  }
  .pr-phone-0 {
    padding-right: 0;
  }
  .pb-phone-0 {
    padding-bottom: 0;
  }
  .pl-phone-0 {
    padding-left: 0;
  }
}
.font-size-12 {
  font-size: 12px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-17 {
  font-size: 17px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-19 {
  font-size: 19px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-21 {
  font-size: 21px;
}
.font-size-22 {
  font-size: 22px;
}
.font-size-23 {
  font-size: 23px;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-25 {
  font-size: 25px;
}
.font-size-26 {
  font-size: 26px;
}
.font-size-27 {
  font-size: 27px;
}
.font-size-28 {
  font-size: 28px;
}
.font-size-29 {
  font-size: 29px;
}
.font-size-30 {
  font-size: 30px;
}
.font-size-31 {
  font-size: 31px;
}
.font-size-32 {
  font-size: 32px;
}
.font-size-33 {
  font-size: 33px;
}
.font-size-34 {
  font-size: 34px;
}
.font-size-35 {
  font-size: 35px;
}
.font-size-36 {
  font-size: 36px;
}
.font-size-37 {
  font-size: 37px;
}
.font-size-38 {
  font-size: 38px;
}
.font-size-39 {
  font-size: 39px;
}
.font-size-40 {
  font-size: 40px;
}
.font-size-41 {
  font-size: 41px;
}
.font-size-42 {
  font-size: 42px;
}
.font-size-43 {
  font-size: 43px;
}
.font-size-44 {
  font-size: 44px;
}
.font-size-45 {
  font-size: 45px;
}
.font-size-46 {
  font-size: 46px;
}
.font-size-47 {
  font-size: 47px;
}
.font-size-48 {
  font-size: 48px;
}
.font-size-49 {
  font-size: 49px;
}
.font-size-50 {
  font-size: 50px;
}
.font-size-51 {
  font-size: 51px;
}
.font-size-52 {
  font-size: 52px;
}
.font-size-53 {
  font-size: 53px;
}
.font-size-54 {
  font-size: 54px;
}
.font-size-55 {
  font-size: 55px;
}
.font-size-56 {
  font-size: 56px;
}
.font-size-57 {
  font-size: 57px;
}
.font-size-58 {
  font-size: 58px;
}
.font-size-59 {
  font-size: 59px;
}
.font-size-60 {
  font-size: 60px;
}
.font-size-61 {
  font-size: 61px;
}
.font-size-62 {
  font-size: 62px;
}
.font-size-63 {
  font-size: 63px;
}
.font-size-64 {
  font-size: 64px;
}
.font-size-65 {
  font-size: 65px;
}
.font-size-66 {
  font-size: 66px;
}
.font-size-67 {
  font-size: 67px;
}
.font-size-68 {
  font-size: 68px;
}
.font-size-69 {
  font-size: 69px;
}
.font-size-70 {
  font-size: 70px;
}
.font-size-71 {
  font-size: 71px;
}
.font-size-72 {
  font-size: 72px;
}
.font-size-73 {
  font-size: 73px;
}
.font-size-74 {
  font-size: 74px;
}
.font-size-75 {
  font-size: 75px;
}
.font-size-76 {
  font-size: 76px;
}
.font-size-77 {
  font-size: 77px;
}
.font-size-78 {
  font-size: 78px;
}
.font-size-79 {
  font-size: 79px;
}
.font-size-80 {
  font-size: 80px;
}
.font-size-81 {
  font-size: 81px;
}
.font-size-82 {
  font-size: 82px;
}
.font-size-83 {
  font-size: 83px;
}
.font-size-84 {
  font-size: 84px;
}
.font-size-85 {
  font-size: 85px;
}
.font-size-86 {
  font-size: 86px;
}
.font-size-87 {
  font-size: 87px;
}
.font-size-88 {
  font-size: 88px;
}
.font-size-89 {
  font-size: 89px;
}
.font-size-90 {
  font-size: 90px;
}
.font-size-91 {
  font-size: 91px;
}
.font-size-92 {
  font-size: 92px;
}
.font-size-93 {
  font-size: 93px;
}
.font-size-94 {
  font-size: 94px;
}
.font-size-95 {
  font-size: 95px;
}
.font-size-96 {
  font-size: 96px;
}
.font-size-97 {
  font-size: 97px;
}
.font-size-98 {
  font-size: 98px;
}
.font-size-99 {
  font-size: 99px;
}
.font-size-100 {
  font-size: 100px;
}
.font-size-200 {
  font-size: 200px;
}
@media (max-width: 1139px) {
  .font-size-39,
  .font-size-40,
  .font-size-41 {
    font-size: 38px;
  }
  .font-size-42,
  .font-size-43 {
    font-size: 40px;
  }
  .font-size-44 {
    font-size: 41px;
  }
  .font-size-45 {
    font-size: 42px;
  }
  .font-size-46,
  .font-size-47 {
    font-size: 43px;
  }
  .font-size-48,
  .font-size-49 {
    font-size: 45px;
  }
  .font-size-50,
  .font-size-51,
  .font-size-52 {
    font-size: 47px;
  }
  .font-size-53,
  .font-size-54,
  .font-size-55,
  .font-size-56,
  .font-size-57 {
    font-size: 49px;
  }
  .font-size-58,
  .font-size-59,
  .font-size-60,
  .font-size-61,
  .font-size-62 {
    font-size: 51px;
  }
  .font-size-63,
  .font-size-64,
  .font-size-65,
  .font-size-66,
  .font-size-67 {
    font-size: 53px;
  }
  .font-size-68,
  .font-size-69,
  .font-size-70,
  .font-size-71,
  .font-size-72 {
    font-size: 55px;
  }
  .font-size-73,
  .font-size-74,
  .font-size-75,
  .font-size-76,
  .font-size-77 {
    font-size: 57px;
  }
  .font-size-78,
  .font-size-79,
  .font-size-80,
  .font-size-81,
  .font-size-82 {
    font-size: 59px;
  }
  .font-size-83,
  .font-size-84,
  .font-size-85,
  .font-size-86,
  .font-size-87 {
    font-size: 61px;
  }
  .font-size-88,
  .font-size-89,
  .font-size-90,
  .font-size-91,
  .font-size-92 {
    font-size: 63px;
  }
  .font-size-93,
  .font-size-94,
  .font-size-95,
  .font-size-96,
  .font-size-97 {
    font-size: 65px;
  }
  .font-size-100,
  .font-size-98,
  .font-size-99 {
    font-size: 67px;
  }
  .font-size-200 {
    font-size: 100px;
  }
}
@media (max-width: 767px) {
  .font-size-17,
  .font-size-18 {
    font-size: 16px;
  }
  .font-size-19,
  .font-size-20 {
    font-size: 18px;
  }
  .font-size-21,
  .font-size-22,
  .font-size-23,
  .font-size-24,
  .font-size-25,
  .font-size-26 {
    font-size: 20px;
  }
  .font-size-27,
  .font-size-28 {
    font-size: 21px;
  }
  .font-size-29,
  .font-size-30 {
    font-size: 22px;
  }
  .font-size-31,
  .font-size-32 {
    font-size: 23px;
  }
  .font-size-33,
  .font-size-34,
  .font-size-35,
  .font-size-36 {
    font-size: 24px;
  }
  .font-size-37,
  .font-size-38 {
    font-size: 25px;
  }
  .font-size-39,
  .font-size-40,
  .font-size-41 {
    font-size: 26px;
  }
  .font-size-42,
  .font-size-43 {
    font-size: 27px;
  }
  .font-size-44,
  .font-size-45,
  .font-size-46,
  .font-size-47 {
    font-size: 28px;
  }
  .font-size-48,
  .font-size-49 {
    font-size: 29px;
  }
  .font-size-50,
  .font-size-51,
  .font-size-52 {
    font-size: 30px;
  }
  .font-size-53,
  .font-size-54,
  .font-size-55,
  .font-size-56,
  .font-size-57 {
    font-size: 32px;
  }
  .font-size-58,
  .font-size-59,
  .font-size-60,
  .font-size-61,
  .font-size-62 {
    font-size: 34px;
  }
  .font-size-63,
  .font-size-64,
  .font-size-65,
  .font-size-66,
  .font-size-67 {
    font-size: 36px;
  }
  .font-size-68,
  .font-size-69,
  .font-size-70,
  .font-size-71,
  .font-size-72 {
    font-size: 38px;
  }
  .font-size-73,
  .font-size-74,
  .font-size-75,
  .font-size-76,
  .font-size-77 {
    font-size: 40px;
  }
  .font-size-78,
  .font-size-79,
  .font-size-80,
  .font-size-81,
  .font-size-82 {
    font-size: 42px;
  }
  .font-size-83,
  .font-size-84,
  .font-size-85,
  .font-size-86,
  .font-size-87 {
    font-size: 44px;
  }
  .font-size-88,
  .font-size-89,
  .font-size-90,
  .font-size-91,
  .font-size-92 {
    font-size: 46px;
  }
  .font-size-93,
  .font-size-94,
  .font-size-95,
  .font-size-96,
  .font-size-97 {
    font-size: 48px;
  }
  .font-size-100,
  .font-size-98,
  .font-size-99 {
    font-size: 50px;
  }
  .font-size-200 {
    font-size: 60px;
  }
}
