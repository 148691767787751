.add-tip {
  background-color: rgb(93, 93, 93);
  color: white;
  border-radius: 8px;
  z-index: 100;
}
.practice-tooplip::before {
  content: '';
  position: absolute;
  margin: -15px 0 0 150px;
  border-bottom: solid 20px #5d5d5d;
  border-left: solid 17px transparent;
  border-right: solid 17px transparent;
}

.add-client {
  padding: 0px 0px 0px 5px;
  color: white;
  font-weight: 600;
}

.single-dashboard {
  padding: 5px 15px;
  font-family: Inter !important;
  font-size: 14px;
  font-weight: 400;
}

.next-block{
  justify-content: flex-end;
  display: flex;
}

.next-btn {
  background-color: white;
  width: fit-content;
  margin: 2px 10px 8px;
  color: black;
  border-radius: 5px;
  padding: 2px 20px;
  cursor: pointer;
}

.add_client-position{
    margin-left: -347px;
    min-width: 275px;
    max-width: 375px;
    margin-top: 17px;
}

.add_client-tooplip::before {
    content: "";
    position: absolute;
    margin: 0px 0px 0px 317px;
    border-bottom: solid 30px transparent;
    border-right: solid 30px transparent;
    border-top: solid 30px #5d5d5d;
}

.add_client-bar-fill {
  height: 100%;
  width: 25%;
  border-radius: 5px;
  background-color: #6e68de;
}

.benchmarked_client-position {
  margin-left: 100px;
  margin-top: calc(
    90vh - 209px - var(--headerbar-height) - var(--footerbar-height)
  )!important;
  min-width: 275px;
  max-width: 375px;
}
.benchmarked_client-tooplip::before {
  content: "";
  position: absolute;
  border-bottom: solid 20px rgb(93, 93, 93);
  border-left: solid 17px transparent;
  border-right: solid 17px transparent;
  margin-left: 50%;
  margin-top: -20px;
}
.benchmarked_client-bar-fill {
  height: 100%;
  width: 50%;
  border-radius: 5px;
  background-color: #6e68de;
}

.critical_metrics-position {
  margin-left: 100px;
  margin-top: calc(
    90vh - 284px - var(--headerbar-height) - var(--footerbar-height)
  )!important;
  min-width: 275px;
  max-width: 375px;
}
.critical_metrics-tooplip::before {
  content: "";
  position: absolute;
  border-bottom: solid 20px rgb(93, 93, 93);
  border-left: solid 17px transparent;
  border-right: solid 17px transparent;
  margin-left: 50%;
  margin-top: -20px;
}
.critical_metrics-bar-fill {
  height: 100%;
  width: 75%;
  border-radius: 5px;
  background-color: #6e68de;
}


.client_dashboard-position {
  margin-left: 100px;
  margin-top: calc(
    90vh - 236px - var(--headerbar-height) - var(--footerbar-height)
  )!important;
  min-width: 275px;
  max-width: 375px;
}
.client_dashboard-tooplip::before {
  content: "";
  position: absolute;
  border-bottom: solid 20px rgb(93, 93, 93);
  border-left: solid 17px transparent;
  border-right: solid 17px transparent;
  margin-left: 50%;
  margin-top: -20px;
}
