.metric-spectrum.custom-tooltip {
    background-color: white;
    color: #82ca9d;
    border: none;
    box-shadow: 0 0 4px 0 #888888;
    ;
    border-radius: 7px;
    padding: 10px;

    .label {
        color: rgb(127, 127, 127)
    }
    .value{
        color: green;
    }

}